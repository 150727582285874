import React from 'react';
import { NavLink } from 'react-router-dom';
import ModelSubMenu from './ModelSubMenu';
import * as Styled from './styles';

const ModelMegaMenu = ({ modelFamily, handleClick }) => {
  return (
    <Styled.MegaMenu className={`megamenu ${modelFamily.slug}`} onClick={handleClick}>
    <div className="press-release">
      <NavLink to={`/${modelFamily.slug}/press-release`}>
        <Styled.SubMenuTitle>Press Release</Styled.SubMenuTitle>
      </NavLink>
    </div>
      <nav>
        {modelFamily.categories.map((c, idx) => {
          return (
            <ModelSubMenu key={`${modelFamily.slug}-${idx}`} familySlug={modelFamily.slug} category={c} handleClick={handleClick} />
          )
        })}
      </nav>
    </Styled.MegaMenu>
  );
};

export default ModelMegaMenu;