import React from 'react';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import * as Styled from './styles';

const MoreIcon = ({ handleClick }) => (
    <Styled.MoreIcon onClick={handleClick}>
      <ChevronRightIcon />
    </Styled.MoreIcon>
  );

export default MoreIcon;
