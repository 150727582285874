export default [
    {
        title: 'RZR® Trail Sport',
        slug: 'rzr-trail-sport',
        category: 'Trail',
        location: { hirez: [], thumbnails: [] },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/hirez/2021-rzr-trail-sport-white-lightning_3q.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/thumbs/2021-rzr-trail-sport-white-lightning_3q.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® Trail Premium',
        slug: 'rzr-trail-premium',
        category: 'Trail',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-premium%20blue_SIX6567_01141.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-premium%20blue_SIX6567_01536.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-premium%20blue_SIX6568_05685.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-premium-blue_SIX6567_01141.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-premium-blue_SIX6567_01536.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-premium-blue_SIX6568_05685.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/hirez/2021-rzr-trail-900-premium-polaris-blue_3q.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/thumbs/2021-rzr-trail-900-premium-polaris-blue_3q.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® Trail Ultimate',
        slug: 'rzr-trail-ultimate',
        category: 'Trail',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-ultimate-900-premium-family_SIX6568_07834.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-ultimate-ghost-gray_SIX6567_06543.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-ultimate-ghost-gray_SIX6568_04372.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-900-ultimate-ghost-gray_SIX6568_07989.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-ultimate-900-premium-family_SIX6568_07834.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_SIX6567_06543.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_SIX6568_04372.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_SIX6568_07989.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2021-rzr-trail-900-ultimate-ghost-gray_pr.png',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2021-rzr-trail-900-ultimate-ghost-gray_3q.png',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2021-rzr-trail-900-ultimate-ghost-gray_frnt.png',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2021-rzr-trail-900-ultimate-ghost-gray_frnt.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR® Trail S 900 Sport',
        slug: 'rzr-trail-s-900-sport',
        category: 'Trail',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-900-white-lightning_SIX6567_04435.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-900-white-lightning_SIX6567_05076.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-900-white-lightning_SIX6567_04435.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-900-white-lightning_SIX6567_05076.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/hirez/2021-rzr-trail-s-900-sport-white-lightning_3q.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/thumbs/2021-rzr-trail-s-900-sport-white-lightning_3q.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20S%20900%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® Trail S 1000 Premium',
        slug: 'rzr-trail-s-1000-premium',
        category: 'Trail',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-1000-900-premium-family_SIX6567_05550.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-1000-premium-blue_SIX6567_01748_b.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-1000-premium-blue_SIX6567_01892_b.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-1000-900-premium-family_SIX6567_05550.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-1000-premium-blue_SIX6567_01748_b.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-1000-premium-blue_SIX6567_01892_b.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/hirez/2021-rzr-trail-s-1000-premium-polaris-blue_3q.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/thumbs/2021-rzr-trail-s-1000-premium-polaris-blue_3q.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20S%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® Trail S 1000 Ultimate',
        slug: 'rzr-trail-s-1000-ultimate',
        category: 'Trail',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-1000-s-ultimate-900-ultimate-family_SIX6567_08639.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-1000-ultimate-ghost-gray_SIX6568_09280.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/hirez/2021-rzr-trail-s-1000-ultimate-premium-family_SIX6568_07098.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-1000-s-ultimate-900-ultimate-family_SIX6567_08639.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-1000-ultimate-ghost-gray_SIX6568_09280.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Location/thumbs/2021-rzr-trail-s-1000-ultimate-premium-family_SIX6568_07098.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/hirez/2021-rzr-trail-s-1000-ultimate-ghost-gray_3q.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RZR-Jan/Studio/thumbs/2021-rzr-trail-s-1000-ultimate-ghost-gray_3q.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Trail%20S%201000%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR XP® 1000 Trails & Rocks',
        slug: 'rzr-xp-1000-trails-rocks',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Trails-&-Rocks-OrangeRust-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-xp-1000-trails-and-rocks-orange-rust-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-xp-1000-trails-and-rocks-orange-rust-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Trails-&-Rocks-OrangeRust-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-trails-and-rocks-orange-rust-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-trails-and-rocks-orange-rust-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6342_12545.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6342_14046.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6540_05036.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6342_12545.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6342_14046.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-trails-rocks-orange-rust-image-riding_SIX6540_05036.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%201000%20Trails%20&%20Rocks.png'
            ]
        }
    },
    {
        title: 'RZR XP® 1000 High Lifter',
        slug: 'rzr-xp-1000-high-lifter',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Highlifter-IndyRed-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Highlifter-IndyRed-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Highlifter-IndyRed-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Highlifter-IndyRed-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Highlifter-IndyRed-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Highlifter-IndyRed-cgi-frnt.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04969.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-highlifter-indy-red-image-riding_SIX6600_04184.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-highlifter-indy-red-image-riding_SIX6600_04384.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04969.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-highlifter-indy-red-image-riding_SIX6600_04184.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-highlifter-indy-red-image-riding_SIX6600_04384.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%201000%20High%20Lifter.png'
            ]
        }
    },
    {
        title: 'RZR XP® 4 1000 High Lifter',
        slug: 'rzr-xp-4-1000-high-lifter',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Highlifter-IndyRed-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-highlifter-indy-red-image-detail_SIX6600_04436.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04393.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04980.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-highlifter-indy-red-image-detail_SIX6600_04436.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04393.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-highlifter-indy-red-image-riding_SIX6600_04980.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%204%201000%20High%20Lifter.png'
            ]
        }
    },
    {
        title: 'RZR® RS1™',
        slug: 'rzr-rs1',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-RS1-StealthBlack-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-RS1-StealthBlack-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-RS1-StealthBlack-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-RS1-StealthBlack-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-RS1-StealthBlack-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-RS1-StealthBlack-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-family-image-lifestyle_SIX6604_01099.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-rs1-stealth-black-image-riding_SIX6284_00625.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-rs1-stealth-black-image-riding_SIX6284_02190.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-family-image-lifestyle_SIX6604_01099.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-rs1-stealth-black-image-riding_SIX6284_00625.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-rs1-stealth-black-image-riding_SIX6284_02190.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20RS1.png'
            ]
        }
    },
    {
        title: 'RZR XP® 1000 Sport',
        slug: 'rzr-xp-1000-sport',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-PolarisBlue-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-BlackOut-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-BlackOut-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-BlackOut-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-PolarisBlue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-PolarisBlue-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-PolarisBlue-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-BlackOut-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-BlackOut-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-BlackOut-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-PolarisBlue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-PolarisBlue-cgi-frnt.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-premium-black-out-image-riding_SIX6342_15768.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-premium-black-out-image-riding_SIX6346_02451.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-sport-polaris-blue-image-riding_SIX6604_00429.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-sport-polaris-blue-image-riding_SIX6604_01452.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-premium-black-out-image-riding_SIX6342_15768.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-premium-black-out-image-riding_SIX6346_02451.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-sport-polaris-blue-image-riding_SIX6604_00429.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-sport-polaris-blue-image-riding_SIX6604_01452.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%201000%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR XP® 1000 Premium',
        slug: 'rzr-xp-1000-premium',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-BlackPearl-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-BlackPearl-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-BlackPearl-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-IndyRed-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-IndyRed-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-Premium-IndyRed-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-BlackPearl-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-BlackPearl-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-BlackPearl-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-IndyRed-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-IndyRed-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-Premium-IndyRed-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-1000-premium-black-pearl-image-riding_SIX6342_15768.tif',],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-1000-premium-indy-red-image-riding_SIX6346_02895.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® Sport',
        slug: 'rzr-pro-xp-sport',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-CruiserBlack-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-CruiserBlack-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-CruiserBlack-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-WhiteLightning-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-WhiteLightning-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-BASE-WhiteLightning-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-CruiserBlack-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-CruiserBlack-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-CruiserBlack-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-WhiteLightning-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-WhiteLightning-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-BASE-WhiteLightning-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-sport-white-lightning-image-riding_SIX6604_00302.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-sport-cruiser-black-image-riding_SIX6444_08669.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-sport-white-lightning-image-riding_SIX6604_00067.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-sport-white-lightning-image-riding_SIX6604_00302.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-sport-cruiser-black-image-riding_SIX6444_08669.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-sport-white-lightning-image-riding_SIX6604_00067.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® Premium',
        slug: 'rzr-pro-xp-premium',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-PREM-MatteOrangeRust-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-premium-orange-rust-image-riding_SIX6451_02980.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-premium-orange-rust-image-riding_SIX6604_02530.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-premium-orange-rust-image-riding_SIX6451_02980.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-premium-orange-rust-image-riding_SIX6604_02530.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® Ultimate',
        slug: 'rzr-pro-xp-ultimate',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-ArmyGreen-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-ArmyGreen-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-ArmyGreen-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-SuperGraphite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-SuperGraphite-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-ULT-SuperGraphite-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-ArmyGreen-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-ArmyGreen-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-ArmyGreen-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-SuperGraphite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-SuperGraphite-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-ULT-SuperGraphite-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-family-image-riding_SIX6604_00231.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-ultimate-super-graphite-image-beauty_SIX6444_02726.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-ultimate-super-graphite-image-riding_SIX6444_05781.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-ultimate-super-graphite-image-riding_SIX6604_02861.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-family-image-riding_SIX6604_00231.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-ultimate-super-graphite-image-beauty_SIX6444_02726.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-ultimate-super-graphite-image-riding_SIX6444_05781.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-ultimate-super-graphite-image-riding_SIX6604_02861.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR Pro XP® Ultimate Rockford Fosgate LE',
        slug: 'rzr-pro-xp-ultimate-rockford-fosgate-le',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-proxp-MatteNavy_pr_cgi_Z22MAU92AP.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-proxp-MatteNavy_3q_cgi_Z22MAU92AP.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-proxp-MatteNavy_frnt_cgi_Z22MAU92AP.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-proxp-MatteNavy_pr_cgi_Z22MAU92AP.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-proxp-MatteNavy_3q_cgi_Z22MAU92AP.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-proxp-MatteNavy_frnt_cgi_Z22MAU92AP.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-4-LE-matte-navy-image-detail_838.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-4-LE-matte-navy-image-detail_838.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Specs/thumbs/RZR%20Pro%20XP%20Ultimate%20Rockford%20Fosgate%20LE.png'
            ]
        }
    },
    {
        title: 'RZR Pro XP® 4 Ultimate Rockford Fosgate LE',
        slug: 'rzr-pro-xp-4-ultimate-rockford-fosgate-le',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-crewproxp-MatteNavy_pr_cgi_Z22M4U92AP.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-crewproxp-MatteNavy_3q_cgi_Z22M4U92AP.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Studio/2022-rzr-crewproxp-MatteNavy_frnt_cgi_Z22M4U92AP.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-crewproxp-MatteNavy_pr_cgi_Z22M4U92AP.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-crewproxp-MatteNavy_3q_cgi_Z22M4U92AP.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-crewproxp-MatteNavy_frnt_cgi_Z22M4U92AP.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-4-LE-matte-navy-image-riding_761.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-4-LE-matte-navy-image-beauty_196.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-4-LE-matte-navy-image-detail_838.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-LE-matte-navy-image-detail_2.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-4-LE-matte-navy-image-riding_761.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-4-LE-matte-navy-image-beauty_196.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-4-LE-matte-navy-image-detail_838.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-LE-matte-navy-image-detail_2.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Specs/thumbs/RZR%20Pro%20XP%204%20Ultimate%20Rockford%20Fosgate%20LE.png'
            ]
        }
    },
    {
        title: 'RZR XP® 4 1000 Sport',
        slug: 'rzr-xp-4-1000-sport',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-BlackOut-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-BlackOut-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-BlackOut-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-PolarisBlue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-PolarisBlue-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-PolarisBlue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-BlackOut-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-BlackOut-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-BlackOut-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-PolarisBlue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-PolarisBlue-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-PolarisBlue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-premium-black-out-image-riding_SIX6342_17011.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-premium-black-out-image-riding_SIX6342_17011.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%204%201000%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR XP® 4 1000 Premium',
        slug: 'rzr-xp-4-1000-premium',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-IndyRed-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-IndyRed-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-XP1000-4-Premium-IndyRed-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-IndyRed-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-BlackPearl-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-IndyRed-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-XP1000-4-Premium-IndyRed-cgi-frnt.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-premium-black-pearl-image-riding_SIX6342_09481.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-premium-indy-red-image-lifestyle_SIX6604_01872.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-4-1000-premium-indy-red-image-riding_SIX6604_01944.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-premium-black-pearl-image-riding_SIX6342_09481.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-premium-indy-red-image-lifestyle_SIX6604_01872.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-4-1000-premium-indy-red-image-riding_SIX6604_01944.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20XP%204%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® 4 Sport',
        slug: 'rzr-pro-xp-4-sport',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-cruiser-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-cruiser-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-4-BASE-CruiserBlack-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-white-lightning-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-white-lightning-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-RZR-ProXP-4-BASE-WhiteLightning-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-cruiser-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-cruiser-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-4-BASE-CruiserBlack-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-white-lightning-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-white-lightning-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-RZR-ProXP-4-BASE-WhiteLightning-cgi-pr.jpg',
            ],
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/Location/2022-rzr-pro-xp-4-sport-white-lightning-image-riding_SIX6604_02698.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Jan/thumbs/2022-rzr-pro-xp-4-sport-white-lightning-image-riding_SIX6604_02698.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%204%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® 4 Premium',
        slug: 'rzr-pro-xp-4-premium',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-premium-matte-orange-rust-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-family-image-lifestyle_SIX6604_02083.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-4-premium-family-image-lifestyle_SIX6444_11709.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-4-premium-orange-rust-image-lifestyle_SIX6452_00173.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-xp-family-image-lifestyle_SIX6539_04529.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-family-image-lifestyle_SIX6604_02083.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-premium-family-image-lifestyle_SIX6444_11709.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-premium-orange-rust-image-lifestyle_SIX6452_00173.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-xp-family-image-lifestyle_SIX6539_04529.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%204%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR PRO XP® 4 Ultimate',
        slug: 'rzr-pro-xp-4-ultimate',
        category: 'Multi-Terrain',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Studio/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-army-green-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-super-graphite-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-4-ultimate-army-green-image-riding_SIX6604_03189.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-4-ultimate-super-graphite-image-riding_SIX6444_06674.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Location/2022-rzr-pro-xp-4-ultimate-super-graphite-image-riding_SIX6539_04180.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-army-green-image-riding_SIX6604_03189.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-super-graphite-image-riding_SIX6444_06674.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/thumbs/2022-rzr-pro-xp-4-ultimate-super-graphite-image-riding_SIX6539_04180.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Pro%20XP%204%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R Sport',
        slug: 'rzr-pro-r-sport',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-sport-stealth-gray-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-sport-stealth-gray-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-sport-stealth-gray-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-gray-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-gray-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-gray-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-pga-family-image-beauty-SIX6545_002487.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-sport-stealth-grey-image-lifestyle_SIX6540_04619.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-sport-stealth-grey-image-lifestyle-SIX6539_01650.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-sport-stealth-grey-image-riding_SIX6539_02236.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-azure-crystal-image-riding-SIX6540_03127.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-pga-family-image-beauty-SIX6545_002487.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-grey-image-lifestyle_SIX6540_04619.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-grey-image-lifestyle-SIX6539_01650.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-sport-stealth-grey-image-riding_SIX6539_02236.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-azure-crystal-image-riding-SIX6540_03127.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R Premium',
        slug: 'rzr-pro-r-premium',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-premium-polaris-blue-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-premium-polaris-blue-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-premium-polaris-blue-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-premium-polaris-blue-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-premium-polaris-blue-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-premium-polaris-blue-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-family-image-riding-SIX6546_07962.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-image-lifestyle-SIX6546_07430.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-family-image-riding-SIX6546_07962.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-image-lifestyle-SIX6546_07430.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R Ultimate',
        slug: 'rzr-pro-r-ultimate',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pror-StealthBlack-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pror-StealthBlack-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pror-StealthBlack-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-crystal-blue-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pror-StealthBlack-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pror-StealthBlack-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pror-StealthBlack-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6624_05184.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-azure-crystal-image-riding_SIX6546_03557.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-azure-crystal-image-riding_SIX6546_05428.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_04062.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_04092.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_05257.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6624_05184.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-azure-crystal-image-riding_SIX6546_03557.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-azure-crystal-image-riding_SIX6546_05428.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_04062.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_04092.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-ultimate-stealth-black-image-riding_SIX6546_05257.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R Ultimate Launch Edition',
        slug: 'rzr-pro-r-ultimate-launch-edition',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-lifted-lime-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-lifted-lime-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-lifted-lime-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-onyx-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-onyx-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2020-rzr-pro-r-le-onyx-black-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-lifted-lime-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-lifted-lime-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-lifted-lime-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-onyx-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-onyx-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-le-onyx-black-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%20Ultimate%20Launch%20Edition.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R 4 Sport',
        slug: 'rzr-pro-r-4-sport',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-sport-stealth-gray-image-cgi-pr.jpg'
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07790.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07812.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07790.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07812.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%204%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R 4 Premium',
        slug: 'rzr-pro-r-4-premium',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-premium-polaris-blue-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07790.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-4-premium-image-beauty-SIX6545_03802.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-4-premium-polaris-blue-image-lifestyle-SIX6545_03579.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-family-image-beauty-SIX6546_07790.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-premium-image-beauty-SIX6545_03802.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-premium-polaris-blue-image-lifestyle-SIX6545_03579.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%204%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R 4 Ultimate',
        slug: 'rzr-pro-r-4-ultimate',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-crewpror-StealthBlack-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-crewpror-StealthBlack-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-crewpror-StealthBlack-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-ultimate-crystal-blue-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-crewpror-StealthBlack-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-crewpror-StealthBlack-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-crewpror-StealthBlack-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2021-rzr-pro-r-4-ultimate-azure-crystal-image-beauty_SIX6546_02166.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6545_05221.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6546_02854.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6546_03400.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2021-rzr-pro-r-4-ultimate-onyx-black-image-beauty_SIX6545_00229.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-pro-r-family-image-riding-SIX6546_07962.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2021-rzr-pro-r-4-ultimate-azure-crystal-image-beauty_SIX6546_02166.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6545_05221.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6546_02854.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2021-rzr-pro-r-4-ultimate-azure-crystal-image-riding_SIX6546_03400.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2021-rzr-pro-r-4-ultimate-onyx-black-image-beauty_SIX6545_00229.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-family-image-riding-SIX6546_07962.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%204%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR® PRO R 4 Ultimate Launch Edition',
        slug: 'rzr-pro-r-4-ultimate-launch-edition',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-onyx-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-onyx-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-onyx-black-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-onyx-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-onyx-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-onyx-black-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-pro-r-4-le-lifted-lime-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20PRO%20R%204%20Ultimate%20Launch%20Edition.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R Sport',
        slug: 'rzr-turbo-r-sport',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-cruiser-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-cruiser-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-cruiser-black-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-cruiser-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-cruiser-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-cruiser-black-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_00406.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_00406.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R Premium',
        slug: 'rzr-turbo-r-premium',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-matte-blue-slate-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-matte-blue-slate-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-matte-blue-slate-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-matte-blue-slate-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-matte-blue-slate-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-matte-blue-slate-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-lifestyle_SIX6620_00025.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6620_03177.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6620_03232.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6625_01170.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6625_03181.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding-SIX6624_02245.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-premium-blue-slate-image-riding-SIX6624_02249.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-lifestyle_SIX6620_00025.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6620_03177.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6620_03232.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6625_01170.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding_SIX6625_03181.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding-SIX6624_02245.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-premium-blue-slate-image-riding-SIX6624_02249.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R Ultimate',
        slug: 'rzr-turbo-r-ultimate',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-ghost-gray-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-ghost-gray-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-ghost-gray-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-onyx-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-onyx-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-onyx-black-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ghost-gray-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ghost-gray-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ghost-gray-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-onyx-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-onyx-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-onyx-black-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_01007.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-ultimate-ghost-gray-image-riding_SIX6620_01967.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-ultimate-ghost-gray-image-riding_SIX6620_02727.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-ultimate-onyx-black-image-beauty_SIX6625_00315.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-ultimate-onyx-black-image-riding_SIX6625_00613.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-ultimate-onyx-black-image-riding_SIX6625_00705.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_01007.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ultimate-ghost-gray-image-riding_SIX6620_01967.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ultimate-ghost-gray-image-riding_SIX6620_02727.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ultimate-onyx-black-image-beauty_SIX6625_00315.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ultimate-onyx-black-image-riding_SIX6625_00613.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-ultimate-onyx-black-image-riding_SIX6625_00705.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R 4 Sport',
        slug: 'rzr-turbo-r-4-sport',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-cruiser-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-cruiser-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-cruiser-black-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-cruiser-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-cruiser-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-cruiser-black-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6624_06811.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6625_02308.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-sport-cruiser-black-image-riding_SIX6624_06149.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6624_06811.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6625_02308.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-sport-cruiser-black-image-riding_SIX6624_06149.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%204%20Sport.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R 4 Premium',
        slug: 'rzr-turbo-r-4-premium',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-matte-blue-slate-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_02067.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_02389.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_03669.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6620_02492.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6624_02515.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6625_00682.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_02067.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_02389.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_03669.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6620_02492.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6624_02515.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-premium-blue-slate-image-riding_SIX6625_00682.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%204%20Premium.png'
            ]
        }
    },
    {
        title: 'RZR® Turbo R 4 Ultimate',
        slug: 'rzr-turbo-r-4-ultimate',
        category: 'Wide Open',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-ghost-Ggray-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-ghost-gray-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-ghost-gray-image-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-onyx-black-image-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-onyx-black-image-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Studio/2022-rzr-turbo-r-4-onyx-black-image-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ghost-Ggray-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ghost-gray-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ghost-gray-image-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-onyx-black-image-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-onyx-black-image-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-onyx-black-image-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6620_03669.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6624_00723.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6624_05184.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6625_01846.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-family-image-lifestyle_SIX6625_02761.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-ghost-gray-image-lifestyle_SIX6625_02681.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-ghost-gray-image-riding_SIX6625_01406.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6620_02897.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6620_03055.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6624_01207.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/Location/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6624_05514.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6620_03669.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6624_00723.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6624_05184.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6625_01846.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-family-image-lifestyle_SIX6625_02761.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-ghost-gray-image-lifestyle_SIX6625_02681.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-ghost-gray-image-riding_SIX6625_01406.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6620_02897.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6620_03055.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6624_01207.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR-Nov/thumbs/2022-rzr-turbo-r-4-ultimate-onyx-black-image-riding_SIX6624_05514.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/hirez/MY22-RZR-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RZR/Specs/thumbs/RZR%20Turbo%20R%204%20Ultimate.png'
            ]
        }
    },
    {
        title: 'Baja 1000',
        slug: 'baja-1000',
        category: 'Race',
        studio: {
            hirez: [],
            thumbnails: []
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [],
            thumbnails: []
        }
    },
];