import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  min-height: 75vh;
  padding: 3rem;
`;

export const Grid = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-right: -0.5rem
`;