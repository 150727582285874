import React from 'react';
import { NavLink } from 'react-router-dom';
import { slufigyReplaceLeadingNumbers } from 'features/shared/services';
import * as Styled from './styles';

const ModelSubMenu = ({ familySlug = '', category = {}, handleClick }) => {
  const categorySlug = slufigyReplaceLeadingNumbers(category.title);
  return (
    <Styled.SubMenu className={`sub-menu ${categorySlug}`} onClick={handleClick}>
      <Styled.SubMenuTitle>{category.title}</Styled.SubMenuTitle>
      <nav className="nested-menu">
        {category.models.map((m, idx) => {
          return (
            <NavLink key={m.slug} to={`/${familySlug}/${m.slug}/`}>{m.title}</NavLink>
          )
        })}
      </nav>
    </Styled.SubMenu>
  );
};

export default ModelSubMenu;