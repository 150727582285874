import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import * as Styled from './styles';

const Logo = () => {
  return (
    <Styled.Logo>
      <NavLink to="/" className="d-md-none">
        <img src="https://1170617057.rsc.cdn77.org/images-MY22/Site/MY22-header-logo-full-870x130.png" alt="Polaris MY 2022 Press Kit" />
      </NavLink>
      <NavLink to="/" className="d-none d-md-block">
        <img src="https://1170617057.rsc.cdn77.org/images-MY22/Site/MY22-header-logo-570x90.png" alt="Polaris MY 2022 Press Kit" />
      </NavLink>
      <NavLink to="/" className="d-none d-md-block">
        <img src="https://1170617057.rsc.cdn77.org/images-MY22/Site/MY22-header-2022-300x130.png" alt="Polaris MY 2022 Press Kit" />
      </NavLink>
    </Styled.Logo>
  )
}

const Header = ({ homePage }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const toggleMobileMenu = () => {
    if (menuOpen) {
      setActiveSubMenu('');
    }
    setMenuOpen(!menuOpen);
  };

  const updateActiveSubMenu = (value) => {
    console.log('updateActiveSubMenu', value)
    if (value === activeSubMenu) {
      setActiveSubMenu('');
    } else {
      setActiveSubMenu(value);
    }
  };

  let headerClassName = menuOpen ? 'mobile-menu-open' : '';
  if (homePage) {
    headerClassName += ' header-home-background';
  }

  return (
    <Styled.Header className={headerClassName}>
      <Styled.Container>
        <Logo />
        <Styled.Hamburger onClick={toggleMobileMenu}>
          {menuOpen ? <CloseIcon /> : <MenuIcon />}
        </Styled.Hamburger>
      </Styled.Container>
      <Nav menuOpen={menuOpen} activeSubMenu={activeSubMenu} toggleMobileMenu={toggleMobileMenu} updateActiveSubMenu={updateActiveSubMenu} />
    </Styled.Header>
  );
};

export default Header;
