export default [
    // {
    //     title: 'All-New Electric RANGER®',
    //     slug: 'all-new-electric-ranger',
    //     category: 'Pre-Release',
    //     location: {
    //         hirez: ['https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/All-NewElectricRANGER.jpg'],
    //         thumbnails: ['https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/All-NewElectricRANGER.jpg']
    //     },
    //     studio: { hirez: [], thumbnails: [] },
    //     specs: { hirez: [], thumbnails: [] },
    // },
    {
        title: 'RANGER 500',
        slug: 'ranger-500',
        category: '2-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/hirez/2020-ranger-500-solar-red_SIX6166_06658.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/thumbs/2020-ranger-500-solar-red_SIX6166_06658.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-500-sage-green_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-500-sage-green_pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-500-solar-red_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-500-solar-red_pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-500-sage-green_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-500-sage-green_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-500-solar-red_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-500-solar-red_pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER 500.png'
            ]
        }
    },
    {
        title: 'RANGER SP 570',
        slug: 'ranger-sp-570',
        category: '2-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-detail_SIX6599_01385.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_00162.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_01041.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_03080.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_03151.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-lifestyle_SIX6602_12170.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-sage-green-image-riding_SIX6599_04165.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-detail_SIX6599_01385.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_00162.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_01041.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_03080.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-lifestyle_SIX6599_03151.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-lifestyle_SIX6602_12170.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sage-green-image-riding_SIX6599_04165.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570-sagegreen-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570-sagegreen-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570-sagegreen-cgi-PR.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sagegreen-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sagegreen-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-sagegreen-cgi-PR.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20SP%20570.png'
            ]
        }
    },
    {
        title: 'RANGER SP 570 Premium',
        slug: 'ranger-sp-570-premium',
        category: '2-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04040.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04048.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04057.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04099.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-detail_SIX6598_06663.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-detail_SIX6599_01810.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_01777.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_02260.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6605_03055.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6599_02202.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6600_02075.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6602_11128.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6605_02672.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04040.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04048.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04057.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_04099.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-detail_SIX6598_06663.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-detail_SIX6599_01810.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_01777.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_02260.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6605_03055.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6599_02202.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6600_02075.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6602_11128.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-riding_SIX6605_02672.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-polarisblue-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-polarisblue-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-polarisblue-cgi-PR.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-ppc-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-ppc-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570premium-ppc-cgi-PR.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-polarisblue-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-polarisblue-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-polarisblue-cgi-PR.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-ppc-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-ppc-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570premium-ppc-cgi-PR.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20SP%20570%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER EV',
        slug: 'ranger-ev',
        category: '2-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/hirez/2020-ranger-ev-polaris-pursuit-camo_12394.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/thumbs/2020-ranger-ev-polaris-pursuit-camo_12394.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-ev-avalanche-gray_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-ev-avalanche-gray_pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-ev-polaris-pursuit-camo_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-ev-polaris-pursuit-camo_pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-ev-avalanche-gray_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-ev-avalanche-gray_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-ev-polaris-pursuit-camo_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-ev-polaris-pursuit-camo_pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20EV.png'
            ]
        }
    },
    {
        title: 'RANGER 570 Full-Size',
        slug: 'ranger-570-full-size',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/hirez/2020-ranger-570-full-size-sage-green_SIX6048_1014.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/hirez/2020-ranger-570-full-size-solar-red_SIX6049_2857.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/thumbs/2020-ranger-570-full-size-sage-green_SIX6048_1014.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Location/thumbs/2020-ranger-570-full-size-solar-red_SIX6049_2857.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-570-full-size-sage-green_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-570-full-size-sage-green_pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-570-full-size-solar-red_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-570-full-size-solar-red_pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-570-full-size-sage-green_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-570-full-size-sage-green_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-570-full-size-solar-red_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-570-full-size-solar-red_pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20570%20Full-Size.png'
            ]
        }
    },
    {
        title: 'RANGER 1000',
        slug: 'ranger-1000',
        category: '3-Seat',
        location: { hirez: [], thumbnails: [] },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000-sagegreen-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000-sagegreen-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000-solarred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000-solarred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-sagegreen-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-sagegreen-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-solarred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-solarred-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%201000.png'
            ]
        }
    },
    {
        title: 'RANGER 1000 EPS',
        slug: 'ranger-1000-eps',
        category: '3-Seat',
        location: { hirez: [], thumbnails: [] },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000eps-sagegreen-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000eps-sagegreen-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000eps-solarred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000eps-solarred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000eps-sagegreen-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000eps-sagegreen-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000eps-solarred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000eps-solarred-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%201000%20EPS.png'
            ]
        }
    },
    {
        title: 'RANGER 1000 Premium',
        slug: 'ranger-1000-premium',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-onxy-black-smoke-image-lifestyle_SIX6605_01980.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-onxy-black-smoke-image-riding_SIX6605_02488.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-onyx-black-smoke-image-riding_SIX6599_00888.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-polaris-blue-image-lifestyle_SIX6455_3186.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-stealth-gray-image-lifestyle_SIX6450_02397.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-onxy-black-smoke-image-lifestyle_SIX6605_01980.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-onxy-black-smoke-image-riding_SIX6605_02488.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-onyx-black-smoke-image-riding_SIX6599_00888.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-polaris-blue-image-lifestyle_SIX6455_3186.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-stealth-gray-image-lifestyle_SIX6450_02397.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-onyxblack-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-onyxblack-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-stealthgray-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-stealthgray-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-onyxblack-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-onyxblack-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-stealthgray-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-stealthgray-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 Premium',
        slug: 'ranger-xp-1000-premium',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-premium-matte-titainium-image-riding_SIX6602_10039.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-premium-matte-titainium-image-riding_SIX6602_10743.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-premium-matte-titanium-image-lifestyle_SIX6598_03145.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-premium-sunset-red-image-riding_SIX6536_10572.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-premium-matte-titainium-image-riding_SIX6602_10039.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-premium-matte-titainium-image-riding_SIX6602_10743.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-premium-matte-titanium-image-lifestyle_SIX6598_03145.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-premium-sunset-red-image-riding_SIX6536_10572.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-XP1000-EPS-PolarisPursuitCamo-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-XP1000-EPS-PolarisPursuitCamo-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-mattetitanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-mattetitanium-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-sunsetred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-sunsetred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-Ranger-XP1000-EPS-PolarisPursuitCamo-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-Ranger-XP1000-EPS-PolarisPursuitCamo-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-mattetitanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-mattetitanium-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-sunsetred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-sunsetred-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 NorthStar Premium',
        slug: 'ranger-xp-1000-northstar-premium',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-premium-sunset-red-image-lifestyle_SIX6598_03817.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-edition-family-image-beauty_SIX6543_00426.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-premium-sunset-red-image-detail_SIX6598_03974.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-premium-sunset-red-image-lifestyle_SIX6598_03817.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-edition-family-image-beauty_SIX6543_00426.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-premium-sunset-red-image-detail_SIX6598_03974.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-sunsetred-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarpremium-sunsetred-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-sunsetred-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarpremium-sunsetred-cgi-3q.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%201000%20NorthStar%20Edition%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 NorthStar Ultimate',
        slug: 'ranger-xp-1000-northstar-ultimate',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-lifestyle_SIX6601_05049.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-premium-sunset-red-image-lifestyle_SIX6598_05051.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-edition-family-image-lifestyle_SIX6504_1714.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03657.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03686.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6601_06414.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-lifestyle_SIX6601_05049.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-premium-sunset-red-image-lifestyle_SIX6598_05051.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-edition-family-image-lifestyle_SIX6504_1714.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03657.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03686.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6601_06414.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-mattetitanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-mattetitanium-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-sunsetred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-sunsetred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-mattetitanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-mattetitanium-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-sunsetred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-sunsetred-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%201000%20NorthStar%20Edition%20Ultimate.png'
            ]
        }
    },
    {
        title: '2023 RANGER XP Kinetic Premium',
        slug: '2023-ranger-xp-kinetic-premium',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_00058.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_05355.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_02936.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_01073.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_01432.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_05107.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_00058.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_05355.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_02936.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_01073.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_01432.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_05107.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-premium-us-icybluepearl-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-premium-us-icybluepearl-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-premium-us-icybluepearl-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-premium-us-icybluepearl-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-premium-us-icybluepearl-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-premium-us-icybluepearl-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%20Kinetic%20Premium.png'
            ]
        },
        sellsheet: 'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/SellSheets/RANGER_XP_Kinetic-Premium.pdf',
    },
    {
        title: '2023 RANGER XP Kinetic Ultimate',
        slug: '2023-ranger-xp-kinetic-ultimate',
        category: '3-Seat',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-family-image-beauty_SIX6631_02334.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6631_04074.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_00058.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_01012.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_05355.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_00055.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_00373.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_01730.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_04983.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6638_01861.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6638_02085.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-polaris-pursuit-camo-image-lifestyle_SIX6631_00107.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Location/2023-ranger-xp-kinetic-polaris-pursuit-camo-image-lifestyle_SIX6631_00942.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-family-image-beauty_SIX6631_02334.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6631_04074.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_00058.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-detail_SIX6638_01012.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6631_05355.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_00055.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-lifestyle_SIX6638_00373.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_01730.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6631_04983.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6638_01861.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-icy-blue-pearl-image-riding_SIX6638_02085.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-polaris-pursuit-camo-image-lifestyle_SIX6631_00107.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2023-ranger-xp-kinetic-polaris-pursuit-camo-image-lifestyle_SIX6631_00942.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-icybluepearl-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-icybluepearl-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-icybluepearl-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/Studio/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-icybluepearl-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-icybluepearl-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-icybluepearl-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/thumbs/2022-rgr-ev-ultimate-us-pursuitcamo-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%20Kinetic%20Ultimate.png'
            ]
        },
        sellsheet: 'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Dec/SellSheets/RANGER_XP_Kinetic-Ultimate.pdf',
    },
    {
        title: 'RANGER SP 570 NorthStar Edition',
        slug: 'ranger-sp-570-northstar-edition',
        category: 'Special Editions',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_03511.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-northstar-polaris-blue-image-detail_SIX6601_05753.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-northstar-polaris-blue-image-detail_SIX6602_02705.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-northstar-polaris-blue-image-lifestyle_SIX6599_02529.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-northstar-polaris-blue-image-riding_SIX6599_02669.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-sp570-northstar-polaris-blue-image-riding_SIX6599_02679.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-premium-polaris-blue-image-lifestyle_SIX6599_03511.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-northstar-polaris-blue-image-detail_SIX6601_05753.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-northstar-polaris-blue-image-detail_SIX6602_02705.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-northstar-polaris-blue-image-lifestyle_SIX6599_02529.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-northstar-polaris-blue-image-riding_SIX6599_02669.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570-northstar-polaris-blue-image-riding_SIX6599_02679.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570northstar-polarisblue-cgi-PR.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570northstar-polarisblue-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-sp570northstar-polarisblue-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570northstar-polarisblue-cgi-PR.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570northstar-polarisblue-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-sp570northstar-polarisblue-cgi-frnt.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER SP 570 NorthStar Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW SP 570 NorthStar Edition',
        slug: 'ranger-crew-sp-570-northstar-edition',
        category: 'Special Editions',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-northstar-polaris-blue-image-riding_SIX6612_01737.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-northstar-polaris-blue-image-detail_SIX6601_05753.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-northstar-polaris-blue-image-riding_SIX6601_05434.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-northstar-polaris-blue-image-riding_SIX6612_01737.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-northstar-polaris-blue-image-detail_SIX6601_05753.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-northstar-polaris-blue-image-riding_SIX6601_05434.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570northstar-polarisblue-cgi-PR.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570northstar-polarisblue-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570northstar-polarisblue-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570northstar-polarisblue-cgi-PR.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570northstar-polarisblue-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570northstar-polarisblue-cgi-frnt.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER CREW SP 570 NorthStar Edition.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 Trail Boss',
        slug: 'ranger-xp-1000-trail-boss',
        category: 'Special Editions',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-trail-boss-ghost-gray-image-detail_SIX6598_06800.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_SIX6536_09892.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_SIX6598_06108.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-trail-boss-ghost-gray-image-detail_SIX6598_06800.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_SIX6536_09892.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_SIX6598_06108.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-trailboss-ghostgray-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-trailboss-ghostgray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-trailboss-ghostgray-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-trailboss-ghostgray-cgi-3q.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER XP 1000 Trail Boss.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 NorthStar Trail Boss',
        slug: 'ranger-xp-1000-northstar-trail-boss',
        category: 'Special Editions',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_plow_00020.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-edition-trail-boss-ghost-gray-image-riding_SIX6542_01099.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-edition-trail-boss-ghost-gray-image-riding_SIX6542_05326.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-trail-boss-ghost-gray-image-riding_SIX6602_04334.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-trail-boss-ghost-gray-image-riding_SIX6602_06004.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-trail-boss-ghost-gray-image-riding_plow_00020.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-edition-trail-boss-ghost-gray-image-riding_SIX6542_01099.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-edition-trail-boss-ghost-gray-image-riding_SIX6542_05326.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-trail-boss-ghost-gray-image-riding_SIX6602_04334.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-trail-boss-ghost-gray-image-riding_SIX6602_06004.jpg',
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-trailboss-ghostgray-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000northstarultimate-trailboss-ghostgray-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-trailboss-ghostgray-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000northstarultimate-trailboss-ghostgray-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%201000%20NorthStar%C2%A0Trail%20Boss.png',
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 Trail Boss',
        slug: 'ranger-crew-xp-1000-trail-boss',
        category: 'Special Editions',
        location: {
            hirez: [],
            thumbnails: []
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-trailboss-ghostgray-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-trailboss-ghostgray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-trailboss-ghostgray-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-trailboss-ghostgray-cgi-3q.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20Trail%20Boss.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 NorthStar Trail Boss',
        slug: 'ranger-crew-xp-1000-northstar-trail-boss',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-trailboss-ghostgray-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-trailboss-ghostgray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-trailboss-ghostgray-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-trailboss-ghostgray-cgi-3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-trail-boss-ghost-gray-image-lifestyle_SIX6543_06449.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-trail-boss-ghost-gray-image-lifestyle_SIX6601_04442.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20NorthStar%20Edition%20Trail%20Boss.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 High Lifter Edition',
        slug: 'ranger-xp-1000-high-lifter-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-highlifter-stealthgray-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-xp1000premium-highlifter-stealthgray-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-highlifter-stealthgray-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp1000premium-highlifter-stealthgray-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04686.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-beauty_SIX6344_02286.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-detail_SIX6438_4355.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6538_06457.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04477.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04644.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04686.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-beauty_SIX6344_02286.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-detail_SIX6438_4355.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6538_06457.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04477.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6600_04644.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20XP%201000%20High%20Lifter%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 High Lifter Edition',
        slug: 'ranger-crew-xp-1000-high-lifter-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-highlifter-stealthgray-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-highlifter-stealthgray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-highlifter-stealthgray-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-highlifter-stealthgray-cgi-3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6438_6422.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6438_5331.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-beauty_SIX6344_02286.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-detail_SIX6438_4302.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6438_6422.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-highlifter-edition-stealth-gray-image-riding_SIX6438_5331.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-beauty_SIX6344_02286.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-highlifter-edition-stealth-gray-image-detail_SIX6438_4302.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20High%20Lifter%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 Texas Edition',
        slug: 'ranger-crew-xp-1000-texas-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-texasedition-blackpearl-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-texasedition-blackpearl-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-texasedition-blackpearl-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-texasedition-blackpearl-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-riding_SIX6606_02612.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6544_10951.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6606_05584.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6606_07591.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6544_07135.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-riding_SIX6606_02612.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6544_10951.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6606_05584.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6606_07591.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-texas-edition-black-pearl-image-lifestyle_SIX6544_07135.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20Texas%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 Waterfowl Edition',
        slug: 'ranger-xp-1000-waterfowl-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20XP%201000%20Waterfowl%20Edition_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20XP%201000%20Waterfowl%20Edition_pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20XP%201000%20Waterfowl%20Edition_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20XP%201000%20Waterfowl%20Edition_pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0060.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0124.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0214-4.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_2865.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-waterfowl-edition-camo-image-riding_0350.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0060.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0124.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_0214-4.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-waterfowl-edition-camo-image-lifestyle_2865.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-waterfowl-edition-camo-image-riding_0350.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Specs/thumbs/2022%20RANGER%20XP%201000%20Waterfowl%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 Waterfowl Edition',
        slug: 'ranger-crew-xp-1000-waterfowl-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Waterfowl%20Edition_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Waterfowl%20Edition_pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Waterfowl%20Edition_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Waterfowl%20Edition_pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2021-ranger-crew-xp-1000-waterfowl-edition-wetlands-camo_0066-4.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2021-ranger-crew-xp-1000-waterfowl-edition-wetlands-camo_0128-4.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2021-ranger-crew-xp-1000-waterfowl-edition-wetlands-camo_0066-4.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2021-ranger-crew-xp-1000-waterfowl-edition-wetlands-camo_0128-4.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Specs/thumbs/2022%20RANGER%20CREW%20XP%201000%20Waterfowl%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER XP 1000 Big Game Edition',
        slug: 'ranger-xp-1000-big-game-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022-ranger-XP1000-EPS-BigGame_PolarisPursuitCamo_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022-ranger-XP1000-EPS-BigGame_PolarisPursuitCamo_pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-XP1000-EPS-BigGame_PolarisPursuitCamo_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-XP1000-EPS-BigGame_PolarisPursuitCamo_pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-lifestyle_SIX6541_06935.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding_SIX6541_04090.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding-SIX6541_07426.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-beauty_0107-2.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-lifestyle_SIX6541_06935.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding_SIX6541_04090.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding-SIX6541_07426.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-beauty_0107-2.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Specs/thumbs/2022%20RANGER%20XP%201000%20Big%20Game%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 Big Game Edition',
        slug: 'ranger-crew-xp-1000-big-game-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Big%20Game%20Edition_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Big%20Game%20Edition_pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Big%20Game%20Edition_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Big%20Game%20Edition_pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-beauty_0107-2.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-big-game-edition-polaris-pursuit-camo-image-lifestyle_SIX6541_07230.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding_SIX6541_03550.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-xp-1000-big-game-edition-polaris-pursuit-camo-image-beauty_0107-2.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-big-game-edition-polaris-pursuit-camo-image-lifestyle_SIX6541_07230.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-big-game-edition-polaris-pursuit-camo-image-riding_SIX6541_03550.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Specs/thumbs/2022%20RANGER%20CREW%20XP%201000%20Big%20Game%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 NorthStar Big Game Edition',
        slug: 'ranger-crew-xp-1000-northstar-big-game-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Northstar%20Big%20Game%20Edition_pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Studio/2022%20RANGER%20CREW%20XP%201000%20Northstar%20Big%20Game%20Edition_3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Northstar%20Big%20Game%20Edition_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022%20RANGER%20CREW%20XP%201000%20Northstar%20Big%20Game%20Edition_3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr15.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr17.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr31.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-riding-MM-rgr09.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Location/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-riding-MM-rgr21.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr15.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr17.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-lifestyle-MM-rgr31.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-riding-MM-rgr09.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/thumbs/2022-ranger-crew-xp-1000-northstar-big-game-edition-polaris-pursuit-camo-image-riding-MM-rgr21.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER-Jan/Specs/thumbs/2022%20RANGER%20CREW%20XP%201000%20Northstar%20Big%20Game%20Edition.png'
            ]
        }
    },
    {
        title: 'RANGER CREW SP 570',
        slug: 'ranger-crew-sp-570',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570-sagegreen-cgi-3Q-1.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570-sagegreen-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570-sagegreen-cgi-frnt.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570-sagegreen-cgi-3Q-1.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570-sagegreen-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570-sagegreen-cgi-frnt.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20SP%20570.png'
            ]
        }
    },
    {
        title: 'RANGER CREW SP 570 Premium',
        slug: 'ranger-crew-sp-570-premium',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-polarisblue-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-polarisblue-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-polarisblue-cgi-PR.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-ppc-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-ppc-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewsp570premium-ppc-cgi-PR.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-polarisblue-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-polarisblue-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-polarisblue-cgi-PR.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-ppc-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-ppc-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewsp570premium-ppc-cgi-PR.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-570-premium-polaris-blue-image-riding_SIX6600_01535.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02505.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02654.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02900.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_02557.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_02610.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_03283.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-riding_SIX6605_02884.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-premium-polaris-blue-image-riding_SIX6605_04271.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_03616.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_03654.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6602_06978.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-570-premium-polaris-blue-image-riding_SIX6600_01535.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02505.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02654.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6600_02900.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_02557.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_02610.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-lifestyle_SIX6605_03283.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-riding_SIX6605_02884.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-premium-polaris-blue-image-riding_SIX6605_04271.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_03616.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6599_03654.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-sp570-ride-command-ppc-image-lifestyle_SIX6602_06978.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20SP%20570%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER CREW 570 Full-Size',
        slug: 'ranger-crew-570-full-size',
        category: 'CREW',
        location: { hirez: [], thumbnails: [] },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-crew-570-full-size-sage-green_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/hirez/2021-ranger-crew-570-full-size-sage-green_pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-crew-570-full-size-sage-green_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/RANGER/Studio/thumbs/2021-ranger-crew-570-full-size-sage-green_pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20570%20Full-Size.png'
            ]
        }
    },
    {
        title: 'RANGER CREW 1000',
        slug: 'ranger-crew-1000',
        category: 'CREW',
        location: { hirez: [], thumbnails: [] },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000-sagegreen-cgi-3Q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000-sagegreen-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000-sagegreen-cgi-3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000-sagegreen-cgi-pr.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%201000.png'
            ]
        }
    },
    {
        title: 'RANGER CREW 1000 Premium',
        slug: 'ranger-crew-1000-premium',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-stealthgray-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-stealthgray-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-stealthgray-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-stealthgray-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6447_00701.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6598_00472.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6598_00739.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-1000-premium-stealth-gray-image-lifestyle_SIX6606_04306.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-1000-premium-stealth-gray-image-riding_SIX6606_03599.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6447_00701.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6598_00472.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-1000-premium-polaris-blue-image-lifestyle_SIX6598_00739.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-1000-premium-stealth-gray-image-lifestyle_SIX6606_04306.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-1000-premium-stealth-gray-image-riding_SIX6606_03599.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 Premium',
        slug: 'ranger-crew-xp-1000-premium',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-mattetitanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-mattetitanium-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-sunsetred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000premium-sunsetred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-mattetitanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-mattetitanium-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-sunsetred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000premium-sunsetred-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-premium-matte-titanium-image-lifestyle_SIX6606_06226.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-premium-matte-titanium-image-riding_SIX6598_04091.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-premium-matte-titanium-image-riding_SIX6598_05835.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-premium-matte-titanium-image-lifestyle_SIX6606_06226.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-premium-matte-titanium-image-riding_SIX6598_04091.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-premium-matte-titanium-image-riding_SIX6598_05835.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 NorthStar Premium',
        slug: 'ranger-crew-xp-1000-northstar-premium',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-mattetitanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-mattetitanium-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-sunsetred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarpremium-sunsetred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-mattetitanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-mattetitanium-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-sunsetred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarpremium-sunsetred-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-detail_SIX6606_06704.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-detail_SIX6606_06706.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-lifestyle_SIX6606_00193.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-lifestyle_SIX6606_00683.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-family-image-beauty_SIX6603_10513.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-detail_SIX6606_06704.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-detail_SIX6606_06706.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-lifestyle_SIX6606_00193.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-premium-ghost-white-metallic-image-lifestyle_SIX6606_00683.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-family-image-beauty_SIX6603_10513.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20NorthStar%20Edition%20Premium.png'
            ]
        }
    },
    {
        title: 'RANGER CREW XP 1000 NorthStar Ultimate',
        slug: 'ranger-crew-xp-1000-northstar-ultimate',
        category: 'CREW',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-ghostwhite-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-ghostwhite-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-mattetitanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-mattetitanium-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-ppc-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-ppc-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-sunsetred-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crewxp1000northstarultimate-sunsetred-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-ghostwhite-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-ghostwhite-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-mattetitanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-mattetitanium-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-ppc-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-ppc-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-sunsetred-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crewxp1000northstarultimate-sunsetred-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-edition-ultimate-polaris-pursuit-camo-image-lifestyle_SIX6542_05221.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-beauty_SIX6598_00136.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-beauty_SIX6598_00202.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_01511.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_06042.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-lifestyle_SIX6598_06978.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-edition-family-image-beauty_SIX6543_00426.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03657.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-edition-ultimate-polaris-pursuit-camo-image-lifestyle_SIX6542_05221.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-beauty_SIX6598_00136.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-beauty_SIX6598_00202.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_01511.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_06042.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew-xp-1000-northstar-ultimate-sunset-red-metallic-image-lifestyle_SIX6598_06978.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-edition-family-image-beauty_SIX6543_00426.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-xp-1000-northstar-ultimate-sunset-red-metallic-image-detail_SIX6598_03657.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%20XP%201000%20NorthStar%20Edition%20Ultimate.png'
            ]
        }
    },
    {
        title: 'RANGER 1000 Premium (+ Winter Prep)',
        slug: 'ranger-1000-premium-winter-prep',
        category: 'Factory-Installed Packages',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-winterprep-polarisblue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-1000premium-winterprep-polarisblue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-winterprep-polarisblue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000premium-winterprep-polarisblue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6455_3381.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6455_3418.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Location/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6503_6749.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6455_3381.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6455_3418.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-1000-premium-winter-prep-package-polaris-blue-image-lifestyle_SIX6503_6749.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%201000%20Premium%20Winter.png'
            ]
        }
    },
    {
        title: 'RANGER CREW 1000 Premium (+ Winter Prep)',
        slug: 'ranger-crew-1000-premium-winter-prep',
        category: 'Factory-Installed Packages',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-polarisblue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Studio/2022-ranger-crew1000premium-polarisblue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-polarisblue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/thumbs/2022-ranger-crew1000premium-polarisblue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/hirez/MY22-RANGER-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/RANGER/Specs/thumbs/RANGER%20CREW%201000%20Premium%20Winter.png'
            ]
        }
    },
];