import styled from 'styled-components';

export const Container = styled.div`
  background-color: #000;
  svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const MobileUnderlay = styled.div`
  background-color: rgba(0,0,0,0.5);
  display: none;
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: transform .4s cubic-bezier(0,0,.2,1);

  .menu-open & {
    display: block;
  }

  @media (min-width: 768px) {
    display: none;
    .menu-open & {
      display: none;
    }
  }
`;

export const FlexNavigation = styled.div`
  background-color: #000;
  position: fixed;
  z-index: 103;
  top: 0;
  right: -270px;
  width: 270px;
  height: 100vh;
  text-align: left;
  transition: transform .4s cubic-bezier(0,0,.2,1);
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;

  .menu-open & {
    transform: translateX(-270px);
  }

  .menu-open.ranger &,
  .menu-open.rzr &,
  .menu-open.general &,
  .menu-open.sportsman &,
  .menu-open.youth &,
  .menu-open.accessories & {
    overflow-y: scroll;
    width: 286px;
    margin-right: -16px;
  }

  @media (min-width: 768px) {
    height: auto;
    max-width: 75rem;
    margin: auto;
    position: relative;
    top: auto;
    right: auto;
    width: auto;
    box-shadow: none;
    background-repeat: repeat-x;
    flex-direction: row;
    justify-content: center;
    min-height: 0;
    transform: none;

    .menu-open & {
      overflow-y: visible;
      transform: none;
    }
  }
`;

export const FlexNavItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
  padding-right: .75rem;
  padding-left: .75rem;
  width: 270px;

  color: #fff;
  cursor: default;
  font-weight: bold;

  &.bg-black,
  &.accessories,
  &.accessories:hover {
    background-color: #222;
    color: #fff;
  }
  &.bg-blue,
  &.active,
  &:hover {
    background-color: #1D579A;
    color: #fff;
  }

  .menu-open.ranger &.ranger  svg,
  .menu-open.general &.general svg,
  .menu-open.rzr &.rzr svg,
  .menu-open.sportsman &.sportsman svg,
  .menu-open.youth &.youth svg,
  .menu-open.accessories &.accessories svg {
    transform: rotateZ(90deg);
  }

  @media (min-width: 768px) {
    flex: 0 1 auto;
    padding-right: .5rem;
    padding-left: .5rem;
    position: static;
    width: auto;

    &.youth,
    &.accessories {
      position: relative;
    }

    &:hover .megamenu,
    &:hover .sub-menu {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const NavItemText = styled.span`
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  @media (min-width: 768px) {
    padding-right: .5rem;
    padding-left: .5rem;
  }
`;

export const SubMenu = styled.div`
  background-color: inherit;
  display: none;
  margin: 0.5rem 0 0;
  text-align: left;

  a {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.75rem 0.5rem;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .nested-nav {
    margin-bottom: 1rem;
    padding-left: 0.75rem;
    a {
      &:first-child {
        margin: 0.75rem 0 0;
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 210px;
    margin: 0;
    padding: 1.5rem;
    opacity: 0;
    visibility: hidden;
    box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    a {
      margin: 0.75rem 0 0;
      padding: 0
      &:first-child {
        margin: 0;
      }
    }
  }
  @media (max-width: 767px) {
    .menu-open.ranger .ranger &,
    .menu-open.general .general &,
    .menu-open.rzr .rzr &,
    .menu-open.sportsman .sportsman &,
    .menu-open.youth .youth &,
    .menu-open.accessories .accessories & {
        display: block;
    }
    .menu-open.youth .youth &,
    .menu-open.accessories .accessories & {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }
`;

export const MegaMenu = styled.div`
  .menu-open & {
    box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.1);
  }

  background-color: rgba(29, 87, 154, 0.95);
  display: none;
  margin: 0 -0.75rem;
  width: calc(100% + 1.5rem);
  text-align: left;

  a {
    color: #fff;
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.75rem 0.5rem;

    &:hover {
      text-decoration: underline;
    }
    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .sub-menu {
    background-color: transparent;
  }

  @media (min-width: 768px) {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
    opacity: 0;
    visibility: hidden;
    box-shadow: 4px 2px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    a {
      margin: 0.75rem 0 0;
      padding: 0
      &:first-child {
        margin: 0;
      }
    }

    > nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .sub-menu {
      background-color: transparent;
      display: block;
      position: relative;
      left: auto;
      top: auto;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      flex: 1 0 auto;
      width: auto;
      max-width: 33.333%;
      flex-basis: 33.333%;
      margin: 0 0 2rem;
      padding: 0;
    }
    .press-release {
      flex: 1 0 auto;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin: 0 0 1rem;
    }

    &.rzr {
      > nav {
          flex-wrap: nowrap;
          justify-content: space-between;
          margin: 0 -0.5rem;
      }
      .sub-menu {
        height: auto;
        flex: 0 1 auto;
        padding: 0 0.5rem;
      }
    }

    &.sportsman {
      > nav {
          flex-direction: column;
          height: 470px;
      }
      .sub-menu {
        height: auto;
        flex: 0 1 auto;
      }
      .xtreme-performance {
          height: 190px;
      }
    }
  }
  @media (max-width: 767px) {
    .menu-open.ranger .ranger &,
    .menu-open.general .general &,
    .menu-open.rzr .rzr &,
    .menu-open.sportsman .sportsman &  {
        display: block;
        margin: 0 -0.75rem;
    }
  }
`;

export const SubMenuTitle = styled.div`
  color: #000;
  display: block;
  font-family: UnitedSans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0.5rem;
  text-transform: uppercase;
  margin: 0;
  @media (min-width: 768px) {
    font-size: 24px;
    padding: 0;
    margin-bottom: 5px;
  }

  .press-release & {
    display: inline-block;
    padding: 0;
  }

  a {
    color: inherit;
  }
`;


export const MoreIcon = styled.div`
  cursor: pointer;
  flex: 0 1 auto;
  @media (min-width: 768px) {
    display: none;
  }
`;