import * as firebase from 'firebase/app';
import 'firebase/auth';

import { firebaseConfig } from 'config/constants';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const embargoDate = new Date('2022-01-18:11:30:00 GMT-0600');
// const now = new Date();
// const SECRETPASSWORD = now > embargoDate ? 'OffRoAdRrGs&22' : 'OffRoAdRrGs&22Jan';
const SECRETPASSWORD = 'OffRoAdRrGs&22';

export const signIn = ({ password }) => {
  return password === SECRETPASSWORD;
};

export const signUp = ({ email, password }) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return firebase.auth().signOut();
};

export const sendPasswordResetEmail = ({ email }) => {
  return firebase.auth().sendPasswordResetEmail(email);
};

export const confirmPasswordReset = ({ code, password }) => {
  return firebase.auth().confirmPasswordReset(code, password);
};

export const sendEmailVerification = () => {
  return firebase.auth().currentUser.sendEmailVerification();
};
