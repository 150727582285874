import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import FourOhFour from 'pages/FourOhFour/FourOhFour';
import Loader from './components/Loader/Loader';
import AuthenticatedRoute from './helpers/AuthenticatedRoute';

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './pages/Home/Home'),
  loading: Loader
});

const AsyncModel = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Model" */ './pages/Model/Model'),
  loading: Loader
});

const AsyncSignIn = Loadable({
  loader: () => import(/* webpackChunkName: "SignIn" */ './pages/Auth/SignIn'),
  loading: Loader
});

const AsyncPressReleaseGeneral = Loadable({
  loader: () => import(/* webpackChunkName: "GeneralPressRelease" */ './pages/PressRelease/General'),
  loading: Loader
});

const AsyncPressReleaseRanger = Loadable({
  loader: () => import(/* webpackChunkName: "RangerPressRelease" */ './pages/PressRelease/Ranger'),
  loading: Loader
});

const AsyncPressReleaseRzr = Loadable({
  loader: () => import(/* webpackChunkName: "RzrPressRelease" */ './pages/PressRelease/Rzr'),
  loading: Loader
});

const AsyncPressReleaseSportsman = Loadable({
  loader: () => import(/* webpackChunkName: "SportsmanPressRelease" */ './pages/PressRelease/Sportsman'),
  loading: Loader
});

const AsyncPressReleaseYouth = Loadable({
  loader: () => import(/* webpackChunkName: "YouthPressRelease" */ './pages/PressRelease/Youth'),
  loading: Loader
});

const AsyncAccessoriesApparel = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesApparel" */ './pages/Accessories/Apparel'),
  loading: Loader
});

const AsyncAccessoriesApparelGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesApparelGallery" */ './pages/Accessories/ApparelGallery'),
  loading: Loader
});

const AsyncAccessoriesGeneral = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesGeneral" */ './pages/Accessories/General'),
  loading: Loader
});

const AsyncAccessoriesGeneralGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesGeneralGallery" */ './pages/Accessories/GeneralGallery'),
  loading: Loader
});

const AsyncAccessoriesKolpin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesKolpin" */ './pages/Accessories/Kolpin'),
  loading: Loader
});

const AsyncAccessoriesKolpinGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesKolpinGallery" */ './pages/Accessories/KolpinGallery'),
  loading: Loader
});

const AsyncAccessoriesPartsLubricants = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesPartsLubricants" */ './pages/Accessories/PartsLubricants'),
  loading: Loader
});

const AsyncAccessoriesPartsLubricantsGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesPartsLubricantsGallery" */ './pages/Accessories/PartsLubricantsGallery'),
  loading: Loader
});

const AsyncAccessoriesPolarisPower = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesPolarisPower" */ './pages/Accessories/PolarisPower'),
  loading: Loader
});

const AsyncAccessoriesPolarisPowerGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesPolarisPowerGallery" */ './pages/Accessories/PolarisPowerGallery'),
  loading: Loader
});

const AsyncAccessoriesProArmorAftermarket = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesProArmorAftermarket" */ './pages/Accessories/ProArmorAftermarket'),
  loading: Loader
});

const AsyncAccessoriesProArmorAftermarketGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesProArmorAftermarketGallery" */ './pages/Accessories/ProArmorAftermarketGallery'),
  loading: Loader
});

const AsyncAccessoriesProArmorWheelsTires = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesProArmorWheelsTires" */ './pages/Accessories/ProArmorWheelsTires'),
  loading: Loader
});

const AsyncAccessoriesProArmorWheelsTiresGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesProArmorWheelsTiresGallery" */ './pages/Accessories/ProArmorWheelsTiresGallery'),
  loading: Loader
});

const AsyncAccessoriesRanger = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRanger" */ './pages/Accessories/Ranger'),
  loading: Loader
});

const AsyncAccessoriesRangerGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRangerGallery" */ './pages/Accessories/RangerGallery'),
  loading: Loader
});

const AsyncAccessoriesRhinoRack = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRhinoRack" */ './pages/Accessories/RhinoRack'),
  loading: Loader
});

const AsyncAccessoriesRhinoRackGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRhinoRackGallery" */ './pages/Accessories/RhinoRackGallery'),
  loading: Loader
});

const AsyncAccessoriesRzr = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRzr" */ './pages/Accessories/Rzr'),
  loading: Loader
});

const AsyncAccessoriesRzrGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesRzrGallery" */ './pages/Accessories/RzrGallery'),
  loading: Loader
});

const AsyncAccessoriesSportsman = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesSportsman" */ './pages/Accessories/Sportsman'),
  loading: Loader
});

const AsyncAccessoriesSportsmanGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesSportsmanGallery" */ './pages/Accessories/SportsmanGallery'),
  loading: Loader
});

const AsyncAccessoriesTrailTech = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesTrailTech" */ './pages/Accessories/TrailTech'),
  loading: Loader
});

const AsyncAccessoriesTrailTechGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesTrailTechGallery" */ './pages/Accessories/TrailTechGallery'),
  loading: Loader
});

const AsyncAccessoriesYouth = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesYouth" */ './pages/Accessories/Youth'),
  loading: Loader
});

const AsyncAccessoriesYouthGallery = Loadable({
  loader: () =>
    import(/* webpackChunkName: "AccessoriesYouthGallery" */ './pages/Accessories/YouthGallery'),
  loading: Loader
});

const Routes = props => {
  return (
    <Switch>
      <AuthenticatedRoute path='/' exact component={AsyncHome} />
      {/*Accessories */}
      <AuthenticatedRoute path='/accessories/ranger' exact component={AsyncAccessoriesRanger} />
      <AuthenticatedRoute path='/accessories/ranger-gallery' exact component={AsyncAccessoriesRangerGallery} />
      <AuthenticatedRoute path='/accessories/general' exact component={AsyncAccessoriesGeneral} />
      <AuthenticatedRoute path='/accessories/general-gallery' exact component={AsyncAccessoriesGeneralGallery} />
      <AuthenticatedRoute path='/accessories/rzr' exact component={AsyncAccessoriesRzr} />
      <AuthenticatedRoute path='/accessories/rzr-gallery' exact component={AsyncAccessoriesRzrGallery} />
      <AuthenticatedRoute path='/accessories/sportsman' exact component={AsyncAccessoriesSportsman} />
      <AuthenticatedRoute path='/accessories/sportsman-gallery' exact component={AsyncAccessoriesSportsmanGallery} />
      <AuthenticatedRoute path='/accessories/youth' exact component={AsyncAccessoriesYouth} />
      <AuthenticatedRoute path='/accessories/youth-gallery' exact component={AsyncAccessoriesYouthGallery} />
      <AuthenticatedRoute path='/accessories/apparel' exact component={AsyncAccessoriesApparel} />
      <AuthenticatedRoute path='/accessories/apparel-gallery' exact component={AsyncAccessoriesApparelGallery} />
      <AuthenticatedRoute path='/accessories/kolpin' exact component={AsyncAccessoriesKolpin} />
      <AuthenticatedRoute path='/accessories/kolpin-gallery' exact component={AsyncAccessoriesKolpinGallery} />
      <AuthenticatedRoute path='/accessories/parts-lubricants' exact component={AsyncAccessoriesPartsLubricants} />
      <AuthenticatedRoute path='/accessories/parts-lubricants-gallery' exact component={AsyncAccessoriesPartsLubricantsGallery} />
      <AuthenticatedRoute path='/accessories/polaris-power' exact component={AsyncAccessoriesPolarisPower} />
      <AuthenticatedRoute path='/accessories/polaris-power-gallery' exact component={AsyncAccessoriesPolarisPowerGallery} />
      <AuthenticatedRoute path='/accessories/pro-armor-aftermarket' exact component={AsyncAccessoriesProArmorAftermarket} />
      <AuthenticatedRoute path='/accessories/pro-armor-aftermarket-gallery' exact component={AsyncAccessoriesProArmorAftermarketGallery} />
      <AuthenticatedRoute path='/accessories/pro-armor-wheels-tires' exact component={AsyncAccessoriesProArmorWheelsTires} />
      <AuthenticatedRoute path='/accessories/pro-armor-wheels-tires-gallery' exact component={AsyncAccessoriesProArmorWheelsTiresGallery} />
      <AuthenticatedRoute path='/accessories/rhino-rack' exact component={AsyncAccessoriesRhinoRack} />
      <AuthenticatedRoute path='/accessories/rhino-rack-gallery' exact component={AsyncAccessoriesRhinoRackGallery} />
      <AuthenticatedRoute path='/accessories/trail-tech' exact component={AsyncAccessoriesTrailTech} />
      <AuthenticatedRoute path='/accessories/trail-tech-gallery' exact component={AsyncAccessoriesTrailTechGallery} />
      {/* Press Releases */}
      <AuthenticatedRoute path='/press-release/ranger' exact component={AsyncPressReleaseRanger} />
      <AuthenticatedRoute path='/press-release/general' exact component={AsyncPressReleaseGeneral} />
      <AuthenticatedRoute path='/press-release/rzr' exact component={AsyncPressReleaseRzr} />
      <AuthenticatedRoute path='/press-release/sportsman' exact component={AsyncPressReleaseSportsman} />
      <AuthenticatedRoute path='/press-release/youth' exact component={AsyncPressReleaseYouth} />
      <AuthenticatedRoute path='/ranger/press-release' exact component={AsyncPressReleaseRanger} />
      <AuthenticatedRoute path='/general/press-release' exact component={AsyncPressReleaseGeneral} />
      <AuthenticatedRoute path='/rzr/press-release' exact component={AsyncPressReleaseRzr} />
      <AuthenticatedRoute path='/sportsman/press-release' exact component={AsyncPressReleaseSportsman} />
      <AuthenticatedRoute path='/youth/press-release' exact component={AsyncPressReleaseYouth} />
      {/* Models */}
      <AuthenticatedRoute path='/ranger/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/general/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/rzr/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/sportsman/:id' component={AsyncModel} history={props.history} />
      <AuthenticatedRoute path='/youth/:id' component={AsyncModel} history={props.history} />
      {/* Sign In */}
      <Route path='/sign-in' exact component={AsyncSignIn} />
      <Route component={FourOhFour} />
    </Switch>
  );
};

export default Routes;
