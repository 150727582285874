import styled from 'styled-components';

export const Header = styled.header`
  position: relative;
  background-color: #1D579A;
  background-image: url(https://1170617057.rsc.cdn77.org/images-MY22/Site/MY22-background-header_model.jpg);
  background-position: center;
  background-repeat-x: no-repeat;
  background-size: cover;
  transition: transform .4s cubic-bezier(.4,0,.2,1);

  &.header-home-background {
    background-image: url(https://1170617057.rsc.cdn77.org/images-MY22/Site/MY22-background-header_home.jpg);
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const Logo = styled.div`
  padding: 1rem 3rem;
  text-align: center;
  img {
    display: block;
    margin: auto;
  }

  @media (min-width: 600px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    .header-home-background & {
      justify-content: center;
    }
    > a {
      flex: 0 1 auto;
      margin: 0 10px;
    }
  }
`;


export const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  background-color: #135195;
  color: #fff;
  width: 3rem;
  padding: 0.75rem;
  @media (min-width: 768px) {
    display: none;
  }
`;
