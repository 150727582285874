export const slufigy = (str) => str.toLowerCase().replace(/\W/g, '-').replace(/--+/g, '-').replace(/-$/, '');

export const slufigyReplaceLeadingNumbers = (str) => {
    const numbersReplaced = str.replace(/^2-/, 'two-').replace(/^3-/, 'three-').replace(/^4-/, 'four-');
    return slufigy(numbersReplaced);
}

export const formatMenuFromProducts = (familyTitle, products) => {
    const categoriesDict = products.reduce((acc, p) => {
        if (acc[p.category] === undefined) {
            acc[p.category] = {
                title: p.category,
                slug: slufigy(p.category),
                models: []
            }
        }
        acc[p.category].models.push(p);
        return acc;
    }, {});

    let categories = Object.values(categoriesDict);

    if (familyTitle === 'RZR') {
        categories = [categoriesDict['Trail'], categoriesDict['Multi-Terrain'], categoriesDict['Wide Open'], categoriesDict['Special Editions'], categoriesDict['Race']]
    }

    return {
        title: familyTitle,
        slug: slufigy(familyTitle),
        categories
    }
}