export default [ 
    {
        title: 'GENERAL 1000 Sport',
        slug: 'general-1000-sport',
        category: '2-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Sport-GhostGray-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Sport-GhostGray-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Sport-GhostGray-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Sport-GhostGray-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-sport-ghost-grey-image-lifestyle_SIX6538_02534.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-sport-ghost-grey-image-lifestyle_SIX6538_02548.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-sport-ghost-grey-image-lifestyle_SIX6539_03475.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-sport-ghost-grey-image-lifestyle_SIX6539_04691.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-sport-ghost-grey-image-riding_SIX6539_03101.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-sport-ghost-grey-image-lifestyle_SIX6538_02534.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-sport-ghost-grey-image-lifestyle_SIX6538_02548.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-sport-ghost-grey-image-lifestyle_SIX6539_03475.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-sport-ghost-grey-image-lifestyle_SIX6539_04691.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-sport-ghost-grey-image-riding_SIX6539_03101.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%201000%20SPORT.png'
            ]
        }
    },
    {
        title: 'GENERAL 1000 Premium',
        slug: 'general-1000-premium',
        category: '2-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Premium-Titanium-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Premium-Titanium-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Premium-Titanium-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Premium-Titanium-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-premium-titanium-metallic-image-riding_SIX6453_01067.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-premium-titanium-metallic-image-beauty_SIX6448_04890.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-premium-titanium-metallic-image-beauty_SIX6453_04720.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-premium-titanium-metallic-image-lifestyle_SIX6448_04850.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-premium-titanium-metallic-image-riding_SIX6448_05383.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-premium-titanium-metallic-image-riding_SIX6453_01067.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-premium-titanium-metallic-image-beauty_SIX6448_04890.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-premium-titanium-metallic-image-beauty_SIX6453_04720.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-premium-titanium-metallic-image-lifestyle_SIX6448_04850.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-premium-titanium-metallic-image-riding_SIX6448_05383.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%201000%20PREMIUM.png'
            ]
        }
    },
    {
        title: 'GENERAL 1000 Deluxe',
        slug: 'general-1000-deluxe',
        category: '2-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Deluxe-SteelyBlue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Deluxe-SteelyBlue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Deluxe-SteelyBlue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Deluxe-SteelyBlue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-riding_SIX6448_02748.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-beauty_0036.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-lifestyle-SIX6599_05042.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-lifestyle-SIX6599_05167.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-riding_SIX6350-297.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-riding_SIX6453_06256.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-steely-blue-image-riding_SIX6599_05197.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-riding_SIX6448_02748.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-beauty_0036.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-lifestyle-SIX6599_05042.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-lifestyle-SIX6599_05167.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-riding_SIX6350-297.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-riding_SIX6453_06256.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-steely-blue-image-riding_SIX6599_05197.jpg',
            ]
        },
        specs: { 
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%201000%20Deluxe.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 1000 Deluxe',
        slug: 'general-xp-1000-deluxe',
        category: '2-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-deluxe-blackcrystal_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-deluxe-blackcrystal_pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-GhostWhiteMetallic-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-GhostWhiteMetallic-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-deluxe-blackcrystal_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-deluxe-blackcrystal_pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-GhostWhiteMetallic-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-GhostWhiteMetallic-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-super-graphite-image-detail_SIX6545_00902.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-black-crystal-image-detail_SIX6602_05939.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6602_04804.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6603_00599.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-black-crystal-image-riding_SIX6603_00801.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ghost-white-image-detail_SIX6603_02181.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ghost-white-image-lifestyle_SIX6602_02915.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ghost-white-image-riding_SIX6602_04191.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-super-graphite-image-detail_SIX6545_00902.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-black-crystal-image-detail_SIX6602_05939.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6602_04804.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6603_00599.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-black-crystal-image-riding_SIX6603_00801.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ghost-white-image-detail_SIX6603_02181.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ghost-white-image-lifestyle_SIX6602_02915.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ghost-white-image-riding_SIX6602_04191.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%20XP%201000%20Deluxe.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 1000 Trailhead Edition',
        slug: 'general-xp-1000-trailhead-edition',
        category: '2-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Trailhead Edition SlateBlue-LE-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Trailhead Edition SlateBlue-LE-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Trailhead Edition SlateBlue-LE-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Trailhead Edition SlateBlue-LE-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-riding_SIX6582_4519.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_1888.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_3492.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_5018.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_5871.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_1406.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_1771.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-riding_2782.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-riding_SIX6582_4519.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_1888.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_3492.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_5018.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_5871.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_1406.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_1771.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-riding_2782.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL XP 1000 Trailhead Edition.png'
            ]
        }
    },
    {
        title: 'GENERAL 4 1000 Premium',
        slug: 'general-4-1000-premium',
        category: '4-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-4-Premium-TitaniumMetallic-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-4-Premium-TitaniumMetallic-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-4-Premium-TitaniumMetallic-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-4-Premium-TitaniumMetallic-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-eps-premium-titanium-metallic-image-beauty_SIX6341_12158.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-eps-premium-titanium-metallic-image-riding_SIX6448_02222.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-eps-premium-titanium-metallic-image-riding_SIX6453_05223.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-premium-titanium-metallic-image-riding_SIX6453_01466.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-eps-premium-titanium-metallic-image-beauty_SIX6341_12158.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-eps-premium-titanium-metallic-image-riding_SIX6448_02222.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-eps-premium-titanium-metallic-image-riding_SIX6453_05223.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-premium-titanium-metallic-image-riding_SIX6453_01466.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%204%201000%20Premium.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 4 1000 Deluxe',
        slug: 'general-xp-4-1000-deluxe',
        category: '4-Seat',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-deluxe-blackcrystal_3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-BlackCrystal-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-GhostWhiteMetallic-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-GhostWhiteMetallic-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-deluxe-blackcrystal_3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-BlackCrystal-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-GhostWhiteMetallic-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-GhostWhiteMetallic-cgi-pr.jpg',
                ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-xp-1000-deluxe-black-crystal-image-detail_SIX6602_03400.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6602_03326.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-xp-1000-deluxe-black-crystal-image-riding_SIX6602_03505.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-black-crystal-image-riding_020_b.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-black-crystal-image-riding_029.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-xp-1000-deluxe-black-crystal-image-detail_SIX6602_03400.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-xp-1000-deluxe-black-crystal-image-lifestyle_SIX6602_03326.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-xp-1000-deluxe-black-crystal-image-riding_SIX6602_03505.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-black-crystal-image-riding_020_b.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-black-crystal-image-riding_029.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%20XP%204%201000%20Deluxe.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 4 1000 Trailhead Edition',
            slug: 'general-xp-4-1000-trailhead-edition',
            category: '4-Seat',
            studio: {
                hirez: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Trailhead Edition SlateBlue-LE-cgi-3q.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Trailhead Edition SlateBlue-LE-cgi-pr.tif',
                ],
                thumbnails: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Trailhead Edition SlateBlue-LE-cgi-3q.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Trailhead Edition SlateBlue-LE-cgi-pr.jpg',
                ]
            },
            location: {
                hirez: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_5224.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_5597.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_7998.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_2578.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_6747.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_3492.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_0644.tif',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_6962.tif',
                ],
                thumbnails: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_5224.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_5597.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_7998.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_2578.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-beauty_SIX6582_6747.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-detail_SIX6582_3492.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_0644.jpg',
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-trailhead-le-matte-blue-slate-metallic-image-lifestyle_SIX6582_6962.jpg',
                ]
            },
            specs: {
                hirez: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
                ],
                thumbnails: [
                    'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL XP 4 1000 Trailhead Edition.png'
                ]
            }
    },
    {
        title: 'GENERAL 1000 Deluxe Ride Command',
        slug: 'general-1000-deluxe-ride-command',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Deluxe-SteelyBlue-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-2-Deluxe-SteelyBlue-RideCommand-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Deluxe-SteelyBlue-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-2-Deluxe-SteelyBlue-RideCommand-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6453_00251.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6540_07448.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-1000-deluxe-ride-command-steely-blue-image-riding_SIX6448_06199.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6453_00251.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6540_07448.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-1000-deluxe-ride-command-steely-blue-image-riding_SIX6448_06199.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%201000%20Deluxe%20Ride%20Command.png'
            ]
        }
    },
    {
        title: 'GENERAL 4 1000 Deluxe Ride Command',
        slug: 'general-4-1000-deluxe-ride-command',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-4-Deluxe-SteelyBlue-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-1000-4-Deluxe-SteelyBlue-RideCommand-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-4-Deluxe-SteelyBlue-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-1000-4-Deluxe-SteelyBlue-RideCommand-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-deluxe-steely-blue-image-lifestyle_SIX6448_03327.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-deluxe-steely-blue-image-lifestyle_SIX6453_00941.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-deluxe-steely-blue-image-riding_SIX6453_01732.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-4-1000-deluxe-steely-blue-image-riding_SIX6453_04516.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-deluxe-steely-blue-image-lifestyle_SIX6448_03327.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-deluxe-steely-blue-image-lifestyle_SIX6453_00941.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-deluxe-steely-blue-image-riding_SIX6453_01732.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-4-1000-deluxe-steely-blue-image-riding_SIX6453_04516.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%204%201000%20Deluxe%20Ride%20Command.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 1000 Deluxe Ride Command',
        slug: 'general-xp-1000-deluxe-ride-command',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-BlackCrystal-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-BlackCrystal-RideCommand-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-BlackCrystal-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-BlackCrystal-RideCommand-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-2-Deluxe-GhostWhiteMetallic-RideCommand-cgi-pr.jpg',
                ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-matte-navy-blue-ride-command-image-detail_SIX6465_0227.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-beauty_SIX6465_0190.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-lifestyle_SIX6561_00989.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-lifestyle_SIX6561_02297.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ride-command-steely-blue-image-beauty_SIX6539_02329.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6448_06295.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-1000-ride-command-ghost-white-metallic-smoke-image-lifestyle-SIX6568_11799.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-matte-navy-blue-ride-command-image-detail_SIX6465_0227.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-beauty_SIX6465_0190.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-lifestyle_SIX6561_00989.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ride-command-ghost-white-metallic-smoke-image-lifestyle_SIX6561_02297.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ride-command-steely-blue-image-beauty_SIX6539_02329.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-deluxe-ride-command-steely-blue-image-lifestyle_SIX6448_06295.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-1000-ride-command-ghost-white-metallic-smoke-image-lifestyle-SIX6568_11799.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%20XP%201000%20Deluxe%20Ride%20Command.png'
            ]
        }
    },
    {
        title: 'GENERAL XP 4 1000 Deluxe Ride Command',
        slug: 'general-xp-4-1000-deluxe-ride-command',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-BlackCrystal-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-BlackCrystal-RideCommand-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-GhostWhiteMetallic-RideCommand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Studio/2022-General-XP-4-Deluxe-GhostWhiteMetallic-RideCommand-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-BlackCrystal-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-BlackCrystal-RideCommand-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-GhostWhiteMetallic-RideCommand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/General-XP-4-Deluxe-GhostWhiteMetallic-RideCommand-cgi-pr.jpg',
                ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-ride-command-black-crystal-image-lifestyle_SIX6541_09645.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-ride-command-black-crystal-image-riding_016.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-ride-command-black-crystal-image-riding_SIX6545_01132.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-ride-command-edition-ghost-white-metallic-smoke-image-beauty_026.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Location/2022-general-xp-4-1000-deluxe-ride-command-edition-ghost-white-metallic-smoke-image-beauty_028.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-ride-command-black-crystal-image-lifestyle_SIX6541_09645.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-ride-command-black-crystal-image-riding_016.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-ride-command-black-crystal-image-riding_SIX6545_01132.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-ride-command-edition-ghost-white-metallic-smoke-image-beauty_026.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/thumbs/general-xp-4-1000-deluxe-ride-command-edition-ghost-white-metallic-smoke-image-beauty_028.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/hirez/MY22-GENERAL-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/GENERAL/Specs/thumbs/GENERAL%20XP%204%201000%20Deluxe%20Ride%20Command.png'
            ]
        }
    }
];