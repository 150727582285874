import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Styled from './styles';

const AccessoriesSubMenu = () => {
  return (
    <Styled.SubMenu className="sub-menu">
      <nav>
        <NavLink to="/accessories/ranger">RANGER</NavLink>
        <NavLink to="/accessories/general">GENERAL</NavLink>
        <NavLink to="/accessories/rzr">RZR</NavLink>
        <NavLink to="/accessories/sportsman">SPORTSMAN</NavLink>
        <NavLink to="/accessories/youth">Youth Model Accessories</NavLink>
        <NavLink to="/accessories/apparel">Apparel</NavLink>
        <NavLink to="/accessories/kolpin">Kolpin</NavLink>
        <NavLink to="/accessories/parts-lubricants">Parts & Lubricants</NavLink>
        <NavLink to="/accessories/polaris-power">Polaris Power</NavLink>
        <NavLink to="/accessories/pro-armor-aftermarket">Pro Armor Aftermarket</NavLink>
        <NavLink to="/accessories/pro-armor-wheels-tires">Pro Armor Wheels & Tires</NavLink>
        <NavLink to="/accessories/rhino-rack">Rhino-Rack<sup>&reg;</sup></NavLink>
        <NavLink to="/accessories/trail-tech">Trail Tech</NavLink>
      </nav>
    </Styled.SubMenu>
  );
};

export default AccessoriesSubMenu;
