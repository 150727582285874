export default [
    {
        title: 'RZR 200 EFI',
        slug: 'rzr-200-efi',
        category: 'Youth',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-RZR-200-WhiteLightning-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-RZR-200-WhiteLightning-cgi-frnt.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-RZR-200-WhiteLightning-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/RZR-200-WhiteLightning-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/RZR-200-WhiteLightning-cgi-frnt.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/RZR-200-WhiteLightning-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-detail_SIX6604_05422.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6603_06656.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6604_05601.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6604_05739.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6605_05835.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6605_05986.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-lifestyle_SIX6605_06334.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-riding_SIX6604_05444.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-riding_SIX6604_05538.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-riding_SIX6605_05111.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-rzr-200-efi-white-image-riding_SIX6605_05536.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-detail_SIX6604_05422.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6603_06656.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6604_05601.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6604_05739.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6605_05835.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6605_05986.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-lifestyle_SIX6605_06334.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-riding_SIX6604_05444.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-riding_SIX6604_05538.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-riding_SIX6605_05111.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/2022-rzr-200-efi-white-image-riding_SIX6605_05536.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/RZR 200 EFI.png'
            ]
        }
    },
    {
        title: 'RANGER 150 EFI',
        slug: 'ranger-150-efi',
        category: 'Youth',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-ranger-150-sagegreen-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-ranger-150-sagegreen-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-ranger-150-whitelightning-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-ranger-150-whitelightning-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-sagegreen-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-sagegreen-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-whitelightning-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-whitelightning-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-green-image-lifestyle_SIX6598_02036.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-green-image-lifestyle_SIX6598_02194.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-green-image-riding_SIX6598_02495.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-sage-green-image-riding_SIX6612_01146.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-sage-green-image-riding_SIX6612_01356.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-ranger-150-efi-sage-green-image-riding_SIX6612_01387.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-green-image-lifestyle_SIX6598_02036.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-green-image-lifestyle_SIX6598_02194.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-green-image-riding_SIX6598_02495.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-sage-green-image-riding_SIX6612_01146.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-sage-green-image-riding_SIX6612_01356.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/ranger-150-efi-sage-green-image-riding_SIX6612_01387.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/RANGER%20150%20EFI.png'
            ]
        }
    },
    {
        title: 'Outlaw 70 EFI',
        slug: 'outlaw-70-efi',
        category: 'Youth',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-Outlaw-Gizmo-Pink-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-Outlaw-Gizmo-Red-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Studio/2022-Outlaw-Gizmo-Red-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/Outlaw-Gizmo-Pink-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/Outlaw-Gizmo-Red-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/Outlaw-Gizmo-Red-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-outlaw-70-white-red-image-riding_SIX6489_1364.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-outlaw-70-white-red-decal-image-lifestyle_SIX6504_3683.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-outlaw-70-white-red-image-beauty_SIX6489_1721.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Location/2022-outlaw-70-white-red-image-lifestyle_SIX6504_4036.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/outlaw-70-white-red-image-riding_SIX6489_1364.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/outlaw-70-white-red-decal-image-lifestyle_SIX6504_3683.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/outlaw-70-white-red-image-beauty_SIX6489_1721.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/thumbs/outlaw-70-white-red-image-lifestyle_SIX6504_4036.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/Outlaw%2070%20EFI.png'
            ]
        }
    },
    {
        title: 'Outlaw 110 EFI',
        slug: 'outlaw-110-efi',
        category: 'Youth',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-outlaw-110-efi-lime-squeeze_SIX6291_00394.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-outlaw-110-lime_SIX6186_0102.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-outlaw-110-lime_SIX6186_0144.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-outlaw-110-efi-lime-squeeze_SIX6291_00394.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-outlaw-110-lime_SIX6186_0102.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-outlaw-110-lime_SIX6186_0144.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-outlaw-110-lime-squeeze-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-outlaw-110-lime-squeeze-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-outlaw-110-pink-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-outlaw-110-pink-pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-outlaw-110-lime-squeeze-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-outlaw-110-lime-squeeze-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-outlaw-110-pink-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-outlaw-110-pink-pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/Outlaw%20110%20EFI.png'
            ]
        }
    },
    {
        title: 'Sportsman 110 EFI',
        slug: 'sportsman-110-efi',
        category: 'Youth',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-sportsman-110-efi-velocity-blue_SIX6186_0224.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-sportsman-110-efi-velocity-blue_SIX6186_0480.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-sportsman-110-efi-velocity-blue_SIX6186_0494.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-sportsman-110-efi-velocity-blue_SIX6186_0224.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-sportsman-110-efi-velocity-blue_SIX6186_0480.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-sportsman-110-efi-velocity-blue_SIX6186_0494.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-sportsman-110-velocity-blue-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-sportsman-110-velocity-blue-pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-sportsman-110-velocity-blue-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-sportsman-110-velocity-blue-pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/Sportsman%20110%20EFI.png'
            ]
        }
    },
    {
        title: 'Phoenix 200',
        slug: 'phoenix-200',
        category: 'Youth',
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-phoenix-200-avalanche-grey_SIX6186_0514.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/hirez/2020-phoenix-200-avalanche-grey_SIX6186_0523.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-phoenix-200-avalanche-grey_SIX6186_0514.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Location/thumbs/2020-phoenix-200-avalanche-grey_SIX6186_0523.jpg'
            ]
        },
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-phoenix-200-avalanche-gray-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/hirez/2020-phoenix-200-avalanche-gray-pr.tif'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-phoenix-200-avalanche-gray-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY21/Youth/Studio/thumbs/2020-phoenix-200-avalanche-gray-pr.jpg'
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/hirez/MY22-Youth-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Youth/Specs/thumbs/Phoenix%20200.png'
            ]
        }
    },
];