export default [
    {
        title: 'Sportsman 450 H.O.',
        slug: 'sportsman-450-h-o',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-EPS-Sage-Green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-EPS-Sage-Green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-EPS-Zenith-Blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-EPS-Zenith-Blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-EPS-Sage-Green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-EPS-Sage-Green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-EPS-Zenith-Blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-EPS-Zenith-Blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20450%20HO.png'
            ]
        }
    },
    {
        title: 'Sportsman 450 H.O. Utility',
        slug: 'sportsman-450-h-o-utility',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-450-utility-sage-green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-450-utility-sage-green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-450-utility-zenith-blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-450-utility-zenith-blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-450-utility-sage-green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-450-utility-sage-green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-450-utility-zenith-blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-450-utility-zenith-blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-450-ho-utility-edition-sage-green-image-beauty_SIX6598_02763.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-450-ho-utility-edition-sage-green-image-lifestyle_SIX6598_02694.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20450%20HO%20Utility.png'
            ]
        }
    },
    {
        title: 'Sportsman 450 H.O. EPS',
        slug: 'sportsman-450-h-o-eps',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-Sage-Green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-Sage-Green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-Zenith-Blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-450-Zenith-Blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-Sage-Green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-Sage-Green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-Zenith-Blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-450-Zenith-Blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20450%20HO%20EPS.png'
            ]
        }
    },
    {
        title: 'Sportsman 570',
        slug: 'sportsman-570',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Desert-Sand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Desert-Sand-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Polaris-Pursuit-Camo-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Polaris-Pursuit-Camo-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Sage-Green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Sage-Green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Zenith-Blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-Sportsman-570-Zenith-Blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Desert-Sand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Desert-Sand-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Polaris-Pursuit-Camo-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Polaris-Pursuit-Camo-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Sage-Green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Sage-Green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Zenith-Blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/Sportsman-570-Zenith-Blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-sage-green-image-lifestyle_SIX6612_00213.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-sage-green-image-riding_SIX6612_00121.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-zenith-blue-image-detail_SIX6600_01940.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-zenith-blue-image-detail_SIX6600_01963.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-zenith-blue-image-detail_SIX6600_01978.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-zenith-blue-image-detail_SIX6600_01990.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-sage-green-image-lifestyle_SIX6612_00213.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-sage-green-image-riding_SIX6612_00121.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-zenith-blue-image-detail_SIX6600_01940.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-zenith-blue-image-detail_SIX6600_01963.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-zenith-blue-image-detail_SIX6600_01978.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-zenith-blue-image-detail_SIX6600_01990.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 EPS',
        slug: 'sportsman-570-eps',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-desert-sand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-sage-green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-sage-green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-zenith-blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-zenith-blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-desert-sand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-sage-green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-sage-green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-zenith-blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-zenith-blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-desert-sand-image-lifestyle_SIX6606_06854.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-desert-sand-image-riding_SIX6606_04801.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-polaris-pursuit-camo-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-polaris-pursuit-camo-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-ppc_image-lifestyle_SIX6603_02912.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-desert-sand-image-lifestyle_SIX6606_06854.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-desert-sand-image-riding_SIX6606_04801.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-polaris-pursuit-camo-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-polaris-pursuit-camo-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-ppc_image-lifestyle_SIX6603_02912.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20EPS.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 EPS Utility',
        slug: 'sportsman-570-eps-utility',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-desert-sand-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-desert-sand-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-sage-green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-sage-green-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-zenith-blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-eps-utility-zenith-blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-desert-sand-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-desert-sand-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-sage-green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-sage-green-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-zenith-blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-utility-zenith-blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20EPS%20Utility.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Premium',
        slug: 'sportsman-570-premium',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-premium-radar-blue-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-premium-radar-blue-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-premium-titanium-metallic-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-premium-titanium-metallic-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-radar-blue-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-radar-blue-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-titanium-metallic-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-titanium-metallic-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-premium-titanium-metallic-image-detail_SIX6543_06102.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-titanium-metallic-image-lifesyle_SIX6541_10213.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20Premium.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Utility HD ',
        slug: 'sportsman-570-utility-hd',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-utility-hd-white-lightning-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-utility-hd-white-lightning-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6544_05967.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6544_06346.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6598_05569.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-utility-hd-white-lightning-image-riding_SIX6599_04496.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6544_05967.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6544_06346.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-utility-hd-white-lightning-image-lifestyle_SIX6598_05569.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-utility-hd-white-lightning-image-riding_SIX6599_04496.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20Utility%20HD.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Trail',
        slug: 'sportsman-570-trail',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-trail-onyx-black-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-trail-onyx-black-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-trail-onyx-black-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-eps-trail-premium-family-image-lifestyle_SIX6538_11017.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-trail-onyx-black-image-lifestyle_SIX6541_02256.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-trail-onyx-black-image-riding_SIX6538_04635.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-trail-onyx-black-image-riding_SIX6538_11495.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-trail-onyx-black-image-riding_SIX6541_02708.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-trail-onyx-black-image-riding_SIX6541_03082.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-eps-trail-premium-family-image-lifestyle_SIX6538_11017.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-image-lifestyle_SIX6541_02256.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-image-riding_SIX6538_04635.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-image-riding_SIX6538_11495.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-image-riding_SIX6541_02708.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-trail-onyx-black-image-riding_SIX6541_03082.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20Trail.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Hunt Edition',
        slug: 'sportsman-570-hunt-edition',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-premium-hunters-edition-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-premium-hunters-edition-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20Hunt%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman 850',
        slug: 'sportsman-850',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-miltary-tan-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-miltary-tan-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-sage-green-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-sage-green-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-miltary-tan-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-miltary-tan-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-sage-green-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-sage-green-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-850-military-tan-image-beauty_SIX6171_02850.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-military-tan-image-beauty_SIX6171_02850.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20850.png'
            ]
        }
    },
    {
        title: 'Sportsman 850 Premium',
        slug: 'sportsman-850-premium',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-polaris-pursuit-camo-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-bronze-mist-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-bronze-mist-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-orange-rust-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-orange-rust-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-premium-polaris-pursuit-camo-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-polaris-pursuit-camo-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-bronze-mist-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-bronze-mist-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-orange-rust-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-orange-rust-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-premium-polaris-pursuit-camo-cgi-3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-850-military-tan-image-riding_SIX6166_07072.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-military-tan-image-riding_SIX6166_07072.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20850%20Premium.png'
            ]
        }
    },
    {
        title: 'Sportsman 850 Ulitmate Trail',
        slug: 'sportsman-850-ulitmate-trail',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-bronze-mist-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-bronze-mist-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-bronze-mist-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-orange-rust-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-orange-rust-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-orange-rust-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-polaris-pursuit-camo-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-ultimate-trail-polaris-pursuit-camo-cgi-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-UltimateTrail-Polaris-Pursuit-Camo-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-bronze-mist-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-bronze-mist-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-bronze-mist-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-orange-rust-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-orange-rust-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-orange-rust-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-polaris-pursuit-camo-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-ultimate-trail-polaris-pursuit-camo-cgi-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-UltimateTrail-Polaris-Pursuit-Camo-cgi-3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-850-military-tan-image-riding_SIX6542_00107.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-military-tan-image-riding_SIX6542_00107.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20850%20Ultimate%20Trail.png'
            ]
        }
    },
    {
        title: 'Sportsman XP 1000 Ultimate Trail',
        slug: 'sportsman-xp-1000-ultimate-trail',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ultimate-trail-stealth-black-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ultimate-trail-stealth-black-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ultimate-trail-stealth-black-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ultimate-trail-stealth-black-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ultimate-trail-stealth-black-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ultimate-trail-stealth-black-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-family-image-lifestyle_SIX6602_00091.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-family-image-lifestyle_SIX6602_00091.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20%20XP%201000%20Ultimate%20Trail.png'
            ]
        }
    },
    {
        title: 'Sportsman XP 1000 Hunt Edition',
        slug: 'sportsman-xp-1000-hunt-edition',
        category: 'Recreation & Utility',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-hunter-edition-polaris-pursuit-camo-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-hunter-edition-polaris-pursuit-camo-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-hunt-edition-polaris-pursuit-camo-image-detail-SIX6381_05565.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-hunt-edition-polaris-pursuit-camo-image-detail-SIX6381_05565.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20%20XP%201000%20Hunt%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman Touring 570',
        slug: 'sportsman-touring-570',
        category: 'Touring',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-Touring-Sonic-Blue-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-Touring-Sonic-Blue-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20Touring%20570.png'
            ]
        }
    },
    {
        title: 'Sportsman Touring 570 EPS',
        slug: 'sportsman-touring-570-eps',
        category: 'Touring',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-Touring-EPS-Titanium-Metallic-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-Touring-EPS-Titanium-Metallic-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20Touring%20570%20EPS.png'
            ]
        }
    },
    {
        title: 'Sportsman Touring 570 Premium',
        slug: 'sportsman-touring-570-premium',
        category: 'Touring',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-touring-premium-nara-bronze-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-touring-premium-nara-bronze-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/2022-sportsman-570-touring-premium-nara-bronze-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/2022-sportsman-570-touring-premium-nara-bronze-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20Touring%20570%20Premium.png'
            ]
        }
    },
    {
        title: 'Sportsman Touring 850',
        slug: 'sportsman-touring-850',
        category: 'Touring',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-touring-navy-blue-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-touring-navy-blue-cgi-3q.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-touring-850-navy-blue-image-riding_SIX6381_01142.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-touring-850-navy-blue-image-riding_SIX6381_01142.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20Touring%20850.png'
            ]
        }
    },
    {
        title: 'Sportsman Touring XP 1000 Trail',
        slug: 'sportsman-touring-xp-1000-trail',
        category: 'Touring',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-touring-pearl-white-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-touring-pearl-white-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-touring-pearl-white-cgi-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-touring-pearl-white-image-riding_SIX6603_04565.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-touring-pearl-white-image-detail_SIX6603_03986.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-touring-pearl-white-image-detail_SIX6603_04295.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-touring-pearl-white-image-lifestyle_SIX6603_04833.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-image-riding_SIX6603_04565.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-image-detail_SIX6603_03986.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-image-detail_SIX6603_04295.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-touring-pearl-white-image-lifestyle_SIX6603_04833.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20Touring%20XP%201000%20Trail.png'
            ]
        }
    },
    {
        title: 'Sportsman XP 1000 S',
        slug: 'sportsman-xp-1000-s',
        category: 'Sport',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-s-super-graphite-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-family-image-lifestyle_SIX6601_00358.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_00333.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_02882.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_04298.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6381_07114.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6603_08610.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6611_00421.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_01507.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_02882.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_03668.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6600_02371.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6600_03986.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6603_08919.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-s-super-graphite-image-riding_SIX6603_09313.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-family-image-lifestyle_SIX6601_00358.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_00333.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_02882.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6380_04298.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6381_07114.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6603_08610.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-lifestyle_SIX6611_00421.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_01507.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_02882.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6380_03668.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6600_02371.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6600_03986.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6603_08919.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-s-super-graphite-image-riding_SIX6603_09313.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20XP%201000%20S.png'
            ]
        }
    },
    {
        title: 'Scrambler 850',
        slug: 'scrambler-850',
        category: 'Sport',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-scrambler-850-orange-burst-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-scrambler-850-orange-burst-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Scrambler%20850.png'
            ]
        }
    },
    {
        title: 'Sportsman 6x6 570',
        slug: 'sportsman-6x6-570',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-6x6-Sagebrush-Green-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-6x6-Sagebrush-Green-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%206x6%20570.png'
            ]
        }
    },
    {
        title: 'Sportsman 850 High Lifter Edition',
        slug: 'sportsman-850-high-lifter-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-850-high-lifter-stealth-gray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-850-high-lifter-stealth-gray-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [],
            thumbnails: []
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20850%20High%20Lifter%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman XP 1000 High Lifter Edition',
        slug: 'sportsman-xp-1000-high-lifter-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-high-lifter-stealth-gray-cgi-3q.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-high-lifter-stealth-gray-cgi-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-850-highlifter-edition-stealth-black-riding_SIX6437_3642.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-highlifter-edition-stealth-black_image-riding_SIX6437_1534.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-highlifter-edition-stealth-black-riding_SIX6437_1249.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-highlifter-edition-stealth-black-riding_SIX6437_1961.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-850-highlifter-edition-stealth-black-riding_SIX6437_3642.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-highlifter-edition-stealth-black_image-riding_SIX6437_1534.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-highlifter-edition-stealth-black-riding_SIX6437_1249.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-highlifter-edition-stealth-black-riding_SIX6437_1961.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20XP%201000%20High%20Lifter%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Ultimate Trail Limited Edition',
        slug: 'sportsman-570-ultimate-trail-limited-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Studio/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Studio/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Front3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Studio/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Left.jpg',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Front3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022_Sportsman_570_Ultimate_Trail_Orange_Madness_CGI_Left.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6538_04512.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6538_11017.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6541_02636.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6541_04760.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_00073.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_00247.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_01337.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_03856_b.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_00985.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_03082.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_04933.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6538_04512.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6538_11017.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6541_02636.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-lifestyle_SIX6541_04760.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_00073.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_00247.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_01337.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6538_03856_b.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_00985.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_03082.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-sportsman-570-ultimate-trail-orange-madeness-image-riding_SIX6541_04933.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Specs/thumbs/Sportsman%20570%20Ultimate%20Trail%20Limited%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman 570 Ride Command Edition',
        slug: 'sportsman-570-ride-command-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-ride-command-LE-azure-crystal-cgi-left-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-ride-command-LE-azure-crystal-cgi-left-front-low-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-ride-command-LE-azure-crystal-cgi-left-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-ride-command-LE-azure-crystal-cgi-left-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-570-ride-command-LE-azure-crystal-cgi-right-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-LE-azure-crystal-cgi-left-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-LE-azure-crystal-cgi-left-front-low-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-LE-azure-crystal-cgi-left-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-LE-azure-crystal-cgi-left-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-LE-azure-crystal-cgi-right-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-detail_SIX6600_04057.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-detail_SIX6600_04062.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-lifestyle_SIX6600_01767.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-lifestyle_SIX6603_01920.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6600_04007.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6602_12681.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6603_02071.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-zenith-blue-image-detail_SIX6603_03729.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-570-ride-command-edition-zenith-blue-image-detail_SIX6603_03823.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-detail_SIX6600_04057.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-detail_SIX6600_04062.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-lifestyle_SIX6600_01767.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-lifestyle_SIX6603_01920.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6600_04007.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6602_12681.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-azure-crystal-image-riding_SIX6603_02071.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-zenith-blue-image-detail_SIX6603_03729.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-570-ride-command-edition-zenith-blue-image-detail_SIX6603_03823.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20570%20Ride%20Command%20Edition.png'
            ]
        }
    },
    {
        title: 'Sportsman XP 1000 Ride Command Edition',
        slug: 'sportsman-xp-1000-ride-command-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-right-pr.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-3q.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-front.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Studio/2022-sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-left-pr.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-right-pr.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-3q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-front.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-LE-azure-crystal-cgi-left-pr.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-lifestyle_SIX6611_00767.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6600_03096.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6601_00625.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6601_00924.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01137.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01271.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01493.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6603_05219.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Location/2022-sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6611_00828.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-lifestyle_SIX6611_00767.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6600_03096.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6601_00625.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6601_00924.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01137.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01271.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6602_01493.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6603_05219.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/thumbs/sportsman-xp-1000-ride-command-edition-azure-crystal-image-riding_SIX6611_00828.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/thumbs/Sportsman%20XP%201000%20Ride%20Command%20Edition.png'
            ]
        }
    },
    {
        title: 'Scrambler XP 1000 S Limited Edition',
        slug: 'scrambler-xp-1000-s-limited-edition',
        category: 'Special Editions',
        studio: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Studio/2022_Scrambler_XP1000S_RadarBlue_CGI_Front3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Studio/2022_Scrambler_XP1000S_RadarBlue_CGI_Left.jpg',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022_Scrambler_XP1000S_RadarBlue_CGI_Front3Q.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022_Scrambler_XP1000S_RadarBlue_CGI_Left.jpg',
            ]
        },
        location: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-beauty_SIX6411_0338.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0445.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0453.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0467.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0475.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-lifestyle_SIX6411_0147.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_5N7A3823.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0191.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0314.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0424.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0485.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0630.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0634.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0670_a.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_1410.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_1700.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6600_02248.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6601_01989.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6611_00893.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6601_01811.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6611_01191.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0431.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0451.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0461.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0465.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0467.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0475.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0482.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0491.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0495.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6600_04075.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0079.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0397.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0478.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0708.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6611_00885.tif',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Location/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6611_01179.tif',
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-beauty_SIX6411_0338.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0445.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0453.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0467.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0475.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-lifestyle_SIX6411_0147.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_5N7A3823.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0191.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0314.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0424.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0485.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0630.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0634.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0670_a.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_1410.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_1700.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6600_02248.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6601_01989.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6611_00893.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6601_01811.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6611_01191.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0431.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0451.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0461.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0465.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0467.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0475.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0482.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0491.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6411_0495.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-detail_SIX6600_04075.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0079.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0397.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0478.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6411_0708.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding_SIX6611_00885.jpg',
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/thumbs/2022-scrambler-xp-1000-s-radar-blue-image-riding-SIX6611_01179.jpg',
            ]
        },
        specs: {
            hirez: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman/Specs/hirez/MY22-Sportsman-Specs.pdf'
            ],
            thumbnails: [
                'https://1170617057.rsc.cdn77.org/images-MY22/Sportsman-Jan/Specs/thumbs/Scrambler%20XP%201000%20S%20Limited%20Edition.png'
            ]
        }
    },
];