import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as services from './services';
import { messageTypes } from 'config/constants';

function* signUpSaga(action) {
  try {
    const response = yield call(services.signUp, action.payload);
    if (response && !response.user.emailVerified) {
      services.sendEmailVerification();
    }
    yield put({
      type: actions.SIGN_UP_SUCCESS,
      entity: response.user,
      message: { type: messageTypes.SUCCESS, text: 'Signed up successfully' }
    });
  } catch (error) {
    yield put({
      type: actions.SIGN_UP_FAIL,
      message: { type: messageTypes.ERROR, text: error.message }
    });
  }
}

function* signInSaga(action) {
  try {
    const response = yield call(services.signIn, action.payload);
    yield put({
      type: response ? actions.SIGN_IN_SUCCESS : actions.SIGN_IN_FAIL,
      authenticated: response,
      message: { type: response ? messageTypes.SUCCESS : messageTypes.ERROR, text: response ? 'Signed in successfully' : 'Invalid password' }
    });
  } catch (error) {
    yield put({
      type: actions.SIGN_IN_FAIL,
      authenticated: false,
      message: { type: messageTypes.ERROR, text: 'Invalid password' }
    });
  }
}

function* signOutSaga() {
  try {
    yield call(services.signOut);
    yield put({
      type: actions.SIGN_OUT_SUCCESS,
      message: { type: messageTypes.SUCCESS, text: 'Signed out successfully' }
    });
  } catch (error) {
    yield put({
      type: actions.SIGN_OUT_FAIL,
      message: { type: messageTypes.ERROR, text: error.message }
    });
  }
}

function* sendResetPasswordMailSaga(action) {
  try {
    yield call(services.sendPasswordResetEmail, action.payload);
    yield put({
      type: actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
      message: {
        type: messageTypes.SUCCESS,
        text: 'Send password reset mail successfully'
      }
    });
  } catch (error) {
    yield put({
      type: actions.SEND_PASSWORD_RESET_EMAIL_FAIL,
      message: { type: messageTypes.ERROR, text: error.message }
    });
  }
}

function* confirmPasswordResetSaga(action) {
  try {
    yield call(services.confirmPasswordReset, action.payload);
    yield put({
      type: actions.CONFIRM_PASSWORD_RESET_SUCCESS,
      message: {
        type: messageTypes.SUCCESS,
        text: 'Confirmed password reset successfully'
      }
    });
  } catch (error) {
    yield put({
      type: actions.CONFIRM_PASSWORD_RESET_FAIL,
      message: { type: messageTypes.ERROR, text: error.message }
    });
  }
}

export function* authSaga() {
  yield takeLatest(actions.SIGN_UP, signUpSaga);
  yield takeLatest(actions.SIGN_IN, signInSaga);
  yield takeLatest(actions.SIGN_OUT, signOutSaga);
  yield takeLatest(
    actions.SEND_PASSWORD_RESET_EMAIL,
    sendResetPasswordMailSaga
  );
  yield takeLatest(actions.CONFIRM_PASSWORD_RESET, confirmPasswordResetSaga);
}
