import React from 'react';
import { formatMenuFromProducts } from 'features/shared/services';
import AccessoriesSubMenu from './AccessoriesSubMenu';
import ModelMegaMenu from './ModelMegaMenu';
import MoreIcon from './MoreIcon';
import SimpleSubMenu from './SimpleSubMenu';

import {
  general,
  ranger,
  rzr,
  sportsman,
  youth
} from 'data/model-families';
import * as Styled from './styles';

const generalMenuData = formatMenuFromProducts('GENERAL', general);
const rangerMenuData = formatMenuFromProducts('RANGER', ranger);
const rzrMenuData = formatMenuFromProducts('RZR', rzr);
const sportsmanMenuData = formatMenuFromProducts('Sportsman', sportsman);
const youthMenuData = formatMenuFromProducts('Youth', youth);


const Nav = ({ menuOpen, activeSubMenu, toggleMobileMenu, updateActiveSubMenu }) => {
  return (
    <Styled.Container className={menuOpen ? `menu-open ${activeSubMenu}` : ''}>
      <Styled.MobileUnderlay onClick={toggleMobileMenu} />
      <Styled.FlexNavigation>
        <Styled.FlexNavItem className="ranger">
          <Styled.NavItemText>RANGER</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('ranger')} />
          <ModelMegaMenu modelFamily={rangerMenuData} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="general">
          <Styled.NavItemText>GENERAL</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('general')} />
          <ModelMegaMenu modelFamily={generalMenuData} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="rzr">
          <Styled.NavItemText>RZR</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('rzr')} />
          <ModelMegaMenu modelFamily={rzrMenuData} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="sportsman">
          <Styled.NavItemText>SPORTSMAN</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('sportsman')} />
          <ModelMegaMenu modelFamily={sportsmanMenuData} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="youth">
          <Styled.NavItemText>YOUTH</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('youth')} />
          <SimpleSubMenu familySlug={youthMenuData.slug} menuItems={youthMenuData.categories[0].models} handleClick={toggleMobileMenu} />
        </Styled.FlexNavItem>
        <Styled.FlexNavItem className="accessories">
          <Styled.NavItemText>Accessories</Styled.NavItemText>
          <MoreIcon handleClick={() => updateActiveSubMenu('accessories')} />
          <AccessoriesSubMenu />
        </Styled.FlexNavItem>
      </Styled.FlexNavigation>
    </Styled.Container>
  );
};

export default Nav;
