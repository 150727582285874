import React from 'react';
import { NavLink } from 'react-router-dom';
import * as Styled from './styles';

const SimpleSubMenu = ({ familySlug, menuItems }) => {
  return (
    <Styled.SubMenu className="sub-menu">
      <nav>
        {menuItems.map(item => {
          return (
            <NavLink key={item.slug} to={familySlug ? `/${familySlug}/${item.slug}` : item.slug}>{item.title}</NavLink>
          )
        })}
      </nav>
    </Styled.SubMenu>
  );
};

export default SimpleSubMenu;